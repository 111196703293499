import React from 'react';
import { withRouter } from "react-router";
import { Button, Modal } from "react-bootstrap";
import {

    Card,
    CardBody,
    Container,
    ModalBody,
    ModalHeader,
} from "reactstrap";

import ProductEditForm from './ProductEditForm';
import Loader from '../../../components/Loader'
import AllowedAdDomainsEditForm from "../allowedDomains/AllowedAdDomainsEditForm";
import { handleErrorResponse } from "../../../utils/ajaxErrorHandler";
import { genericFetcherFactory } from '../../../utils/requestUtils';

class NewProduct extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            formSaving: false,
            domainsModal: false,
            productObj: null
        }

        this.save = this.save.bind(this);
        this.updateStateCallback = this.updateStateCallback.bind(this);
    }

    updateStateCallback(key, value) {
        this.setState({ [key]: value });
        if (!this.state.domainsModal) {
            this.props.history.push('/prods-accounts/products');
        }
    }

    save(productObj) {
        genericFetcherFactory(
            "/api/product/Products",
            "NEW PRODUCT",
            "Failed to save new product",
            "POST",
            {
                method: 'POST',
                body: JSON.stringify(productObj),
                headers: { 'Content-Type': 'application/json' }
            })().then(
                (response) => {
                    if (response.success === true) {
                        this.setState({ productObj: response.data, domainsModal: true });
                    } else {
                        this.setState({ ...this.state, formSaving: false });
                        handleErrorResponse(response, "Save Failed");
                    }
                }
            )
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.formSaving === true} centered>
                    <ModalHeader>
                        Saving Product...
                    </ModalHeader>
                    <ModalBody className="text-center m-3">
                        <Loader />
                    </ModalBody>
                </Modal>

                <Container fluid className="p-0">
                    <h1 className="h3 mb-3">Create new product</h1>
                    <Card>
                        <CardBody>
                            <ProductEditForm submitCallback={this.save} history={this.props.history} />
                            <>
                                <Modal show={this.state.domainsModal === true} size="lg" centered>
                                    <Button className='col-12' variant="secondary" onClick={e => this.setState({ domainsModal: false })}>
                                        Close
                                    </Button>
                                    <Modal.Header>Add Allowed Domain</Modal.Header>
                                    <Modal.Body className="text-center m-2">
                                        <Container fluid className="mb-0">
                                            <AllowedAdDomainsEditForm
                                                selectedProduct={this.state.productObj}
                                                updateStateCallback={this.updateStateCallback}
                                                usedAsModal='true'
                                                customWidth='col-12'
                                            />
                                        </Container>
                                    </Modal.Body>
                                </Modal>
                            </>
                        </CardBody>
                    </Card>
                </Container>
            </>
        )
    }
}

export default withRouter(NewProduct);