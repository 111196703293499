import React, { Component } from 'react';
import TextField from '@mui/material/TextField';

class AdSetExpansionPanelContent extends Component {
  constructor(props) {
    super(props);


    this.state = {
      touched: { page_post_id: false, ad_name: false, },
      errors: { page_post_id: false, ad_name: false, },
      changedField: false,
    };

    this.originalValues = {
      page_post_id: this.props.currentRow ? this.props.currentRow.page_post_id : '',
      ad_name: this.props.currentRow ? this.props.currentRow.ad_name : ''
    };
  }

  validate = (field, value) => {
    return value.trim().length > 0;
  };

  // onBlur event handler.
  onBlurHandler = (e) => {
    const fieldName = e.target.name;

    this.setState((prevState) => ({
      touched: {
        ...prevState.touched,
        [fieldName]: true,
      },
    }));

    const isValid = this.validate(fieldName, e.target.value);

    // Update the error state based on validation.
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [fieldName]: !isValid,
      },
    }));
  };

  onChangeHandler = (e) => {
    const fieldName = e.target.name;
    const value = e.target.value;
    const isValid = this.validate(fieldName, value);

    // If the field has been touched, validate and update errors.
    if (this.state.touched[fieldName]) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          [fieldName]: !isValid,
        },
      }));
    }

    // Check if the new value != original value.
    if (!this.state.changedField) {
      if ((fieldName === 'page_post_id' && value !== this.originalValues.page_post_id) || (fieldName === 'ad_name' && value !== this.originalValues.ad_name)) {
        this.setState({ changedField: true });
      }
    }

    // Create an updated row object
    const updatedRow = { ...this.props.currentRow, isValid };

    const computedTouched = {
      ...this.state.touched,
      [fieldName]: true,
    };

    // Determine if both fields have been touched.
    const allTouched = computedTouched.page_post_id && computedTouched.ad_name;

    this.props.handleFieldChange(e, { originalValues: this.originalValues, row: updatedRow, allTouched, });
  };

  render() {
    const { touched, errors } = this.state;
    const { currentRow } = this.props;

    return (
      <div style={{ display: 'flex', gap: '10px', padding: 10 }}>
        <TextField
          label="Post ID"
          name="page_post_id"
          value={currentRow.page_post_id}
          onChange={this.onChangeHandler}
          onBlur={this.onBlurHandler}
          error={touched.page_post_id && errors.page_post_id}
          helperText={touched.page_post_id && errors.page_post_id ? 'Post ID is required.' : ''}
        />

        <TextField
          label="Ad Name"
          name="ad_name"
          value={currentRow ? currentRow.ad_name : ''}
          onChange={this.onChangeHandler}
          onBlur={this.onBlurHandler}
          error={touched.ad_name && errors.ad_name}
          helperText={touched.ad_name && errors.ad_name ? 'Ad Name is required.' : ''}
          style={{ width: '1000px' }}
        />
      </div>
    );
  }
}

export default AdSetExpansionPanelContent;
