import React from "react";
import {Row } from "react-bootstrap";


export const fieldsText = {
    page_post_id: "Post Id",
    ad_name: "Ad Name"

}
export default class ConfirmationTableViewAdSetExpansion extends React.PureComponent {

    constructor(props) {
        super(props);
        this.setFormColums = this.setFormColums.bind(this);
        this.setData = this.setData.bind(this);
    }

    setFormColums() {
        let cols = [
            {
                dataField: "current_page_post_id",
                text: "Current Page ID",
                sort: false,
                classes: "currentValue",
                headerClasses: 'col-sm-2'
            },
            {
                dataField: "page_post_id",
                text: "New Page ID",
                sort: false,
                classes: "newValue",
                headerClasses: 'col-sm-2'
            },
            {
                dataField: "current_ad_name",
                text: "Current Ad Name",
                sort: false,
                classes: "currentValue ellipsis-text",
                headerClasses: 'col-sm-4',
                attrs: (cell) => ({ title: cell })
            },
            {
                dataField: "ad_name",
                text: "New Ad Name",
                sort: false,
                classes: "newValue ellipsis-text",
                headerClasses: 'col-sm-4',
                attrs: (cell) => ({ title: cell })
            }
        ]


        return cols;
    }


    setData(userAction, adset) {
        let data = [];
        let fieldTextsKeys = Object.keys(fieldsText);
        let changedKeys = Object.keys(userAction);

        const changedRow = {
            adset_id: userAction.adset_id,
        }
        changedKeys.forEach(element => {
            if (fieldTextsKeys.includes(element)) {
                changedRow['current_' + element] = adset[element];
                changedRow[element] = userAction[element];
            }
        });
        data.push(changedRow);
        return data;
    }

    render() {
        return (
            <div id="ConfirmationTableViewAdSetExpansion" className="ConfirmationTableViewAdSetExpansion">

                {this.props.userActions.map(userAction => {
                    let currentAdset = this.props.adsets.find(adset => adset.adset_id === userAction.adset_id);
                    return (
                        <>
                            <Row className="border-bottom border-dark pt-2 mb-2">
                                <h4 className="title text-break">{currentAdset.adset_name}</h4>
                                <div className="row">
                                    <div className="col-6 col-md-2 pe-0">
                                        <div className="border-bottom">Current Page ID</div>
                                        <div className="currentValue">{currentAdset.page_post_id}</div>
                                    </div>

                                    <div className="col-6 col-md-2 ps-0">
                                        <div className="border-bottom">New Page ID</div>
                                        <div className="newValue">{userAction.page_post_id}</div>
                                    </div>

                                    <div className="col-6 col-md-4 pe-0">
                                        <div className="border-bottom">Current Ad Name</div>
                                        <div className="text-break pe-1 currentValue">{currentAdset.ad_name}</div>
                                    </div>


                                    <div className="col-6 col-md-4 ps-0">
                                        <div className="border-bottom">New Ad Name</div>
                                        <div className="text-break ps-1 newValue">{userAction.ad_name}</div>
                                    </div>

                                </div>
                            </Row>
                            {/*<Row >
                                <div className="table-responsive p-0" xs lg="15" >
                                    <BootstrapTable
                                        size="sm"
                                        bootstrap4
                                        keyField="id"
                                        hover
                                        bordered={false}
                                        data={this.setData(userAction, currentAdset)}
                                        columns={this.setFormColums(userAction)}
                                    />
                                </div>
                            </Row> */}
                        </>
                    );
                })}
            </div>
        )
    }
}

