import React from "react";
import { Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons"

export default class MobileOnlyAlert extends React.PureComponent {
    constructor(props) {
        super(props);
        this.message = this.props.message
    }

    render() {
        return (
            <Alert className="d-sm-none mb-2 minView">
                <div className="alert-icon">
                    <FontAwesomeIcon icon={faBell} fixedWidth />
                </div>
                <div className="alert-message">Please rotate your mobile phone for better view</div>
            </Alert>
        )
    }
}

