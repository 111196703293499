import React from "react";
import { Button, Card, Container, Form, Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { Type } from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { Loader } from "react-feather";
import { connect } from "react-redux";
import Select from "react-select";
import {

    Label,
    Progress, Spinner
} from "reactstrap";
import AccountDropdown from "../../../components/AccountDropdown";
import { PushNotifications } from "../../../components/PushNotifications";
import { genericFetcherFactory } from "../../../utils/requestUtils";
import { AccountIsActive, Platforms } from "../../prods-and-accounts/account/accountUtils";
import InfoBar from "../../../components/InfoBar";



const BudgetType = {
    dailyBudget: "Daily Budget",
    lifeTimeBudget: "Life Time Budget"
}

const BudgetTypeTopic = {
    dailyBudget: "DAILY",
    lifeTimeBudget: "LIFETIME"
}

const BUDGET_TOPIC = "budget-report-";

const status = {
    started: "started",
    in_progress: "inProgress",
    done: "done"
}

class BudgetTool extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            budget: [],
            savingBudget: false,
            selectedAccount: null,
            budgetType: null,
            isReportOnly: true,
            openModal: false,
            shouldGetNotifications: false,
            allReports: [],
            accountStatus: null,
            progress: null,
            isFromTool: true

        }
        this.accountChanged = this.accountChanged.bind(this);
        this.updateStateCallback = this.updateStateCallback.bind(this);
        this.sendBudgetToQueue = this.sendBudgetToQueue.bind(this);
        this.generateReportTable = this.generateReportTable.bind(this);
    }

    componentDidMount() {
    }

    updateStateCallback(key, value) {
        this.setState({ [key]: value });
    }

    async accountChanged(account) {
        if (account !== undefined) {
            this.setState({ selectedAccount: account });
        }
    }

    //prepare account object from accountId, and acccount progress status
    prepareAccountObjects(account) {
        //prepare account object 
        account.accountType = Platforms.FACEBOOK;
        //prepare account progress status                   
        this.setState({ accountStatus: account.accountId });
        return account;
    }

    sendBudgetToQueue() {
        let budget = { ...this.state.budget };
        budget.account = this.prepareAccountObjects(this.state.selectedAccount);
        budget.budgetType = this.state.budgetType.label;
        budget.isReportOnly = this.state.isReportOnly;
        budget.isFromTool = this.state.isFromTool;
        this.setState({ savingBudget: true });
        genericFetcherFactory(`/api/facebook-interface/BudgetingTool/start`, "SAVE_BUDGET", "Failed to save budget", "POST", {
            method: "POST",
            body: JSON.stringify(budget),
            headers: { "Content-Type": "application/json" }
        })().then(result => {
            let state = { savingBudget: false };
            if (result.success === true) {
                state.openModal = true;
                state.progress = status.started;
                state.shouldGetNotifications = true;
            }
            this.setState({ ...state });
        });
    }

    checkboxFormatter(cell, row, index) {
        return <Form.Check
            type="switch"
            id={`updateStatus-${row.entityId}`}
            name={`updateStatus-${row.entityId}`}
            checked={row.status}
        />
    }

    generateReportTable() {
        let field = this.state.budgetType.label == BudgetType.dailyBudget ? "dailyBudget" : "newLifeTimeBudget";
        let txt = this.state.budgetType.label == BudgetType.dailyBudget ? "Daily Budget" : "New Life Time Budget";

        let columns = [
            {
                dataField: "calcType",
                text: "Budget Type",
                align: "center",
                headerStyle: { width: "65px" },
                filter: textFilter({ placeholder: '', style: { width: "80%" } }),
                sort: true
            },
            {
                dataField: "entityId",
                text: "Adset ID",
                align: "left",
                headerStyle: { width: "160px" },
                filter: textFilter({ placeholder: '', style: { width: "80%" } }),
                sort: true
            },
            {
                dataField: "entityName",
                text: "Adset Name",
                align: "left",
                style: { fontSize: "85%", maxWidth: "100px", overflow: "hidden", textOverflow: "ellipsis" },
                headerStyle: { width: "200px" },
                filter: textFilter({ placeholder: '', style: { width: "100%" } }),
                sort: true
            },
            {
                dataField: field,
                text: txt,
                align: "center",
                headerStyle: { width: "85px" },
                filter: textFilter({ placeholder: '', style: { width: "80%" } }),
                sort: true
            },
            {
                dataField: "newEndDate",
                text: "New End Date",
                align: "left",
                headerStyle: { width: "150px" },
                filter: textFilter({ placeholder: '', style: { width: "80%" } }),
                sort: true
            },
            {
                dataField: "status",
                text: "Update Succeeded",
                sort: true,
                headerStyle: { width: "85px" },
                editable: false,
                formatter: (cell, row, rowIndex) => this.checkboxFormatter(cell, row, rowIndex)
            },
            {
                dataField: "responseMessage",
                text: "Response Message",
                sort: false,
                style: { fontSize: "80%" }, headerStyle: { width: "200px" },
                editor: { type: Type.TEXT },
                filter: textFilter({ style: { width: "80%" } }),
                align: "center",
                formatter: (cell, row) => {
                    if (row.responseMessage != null) {
                        let errorObj = JSON.parse(row.responseMessage);
                        if (errorObj.error !== undefined) {
                            return errorObj.error.message
                        } else {
                            return row.responseMessage;
                        }
                    }
                }
            }
        ];

        return (
            <BootstrapTable
                bootstrap4
                keyField="entityId"
                bordered={true}
                striped
                hover
                data={this.state.allReports}
                columns={columns}
                filter={filterFactory()}
            />)
    }

    render() {
        let budgetOptions = Object.entries(BudgetType).map(([key, value]) => { return { label: value, value: key } });

        return (
            <Container fluid className="p-0">
                <Modal show={this.state.savingBudget === true} centered>
                    <Modal.Header>
                        Saving Budget...
                    </Modal.Header>
                    <Modal.Body className="text-center m-3">
                        <Loader />
                    </Modal.Body>
                </Modal>

                <Modal size="xl" show={this.state.openModal === true}
                    toggle={() => this.setState({ openModal: false })} centered>
                    <Modal.Header style={{ justifyContent: "flex-start" }} toggle={() => this.setState({ openModal: false })}>
                        <Button variant="secondary" style={{ position: "absolute", top: "10px", right: "10px" }}
                            onClick={e => this.setState({
                                openModal: false,
                                allReports: [],
                                shouldGetNotifications: false,
                                progress: null
                            })}>
                            Close
                        </Button>
                        <div>Budget Report Status:</div>

                        {this.state.progress !== null &&
                            <div class="proggress-wrapper">
                                <Progress centered style={{ marginLeft: "1rem" }}
                                    animated color={this.state.progress == status.done ? "success" : "primary"}
                                    value={this.state.progress == status.done ? "100" : this.state.progress}>
                                    &nbsp;&nbsp;{this.state.progress} %&nbsp;&nbsp;
                                </Progress>
                            </div>
                        }
                    </Modal.Header>
                    <Modal.Body >
                        {(this.state.progress == status.in_progress || this.state.progress == status.done) &&
                            <>{this.generateReportTable()}</>
                        }
                        {this.state.progress == status.started &&
                            < Spinner color="dark" className="mr-2" />
                        }
                    </Modal.Body>
                </Modal>

                {this.state.shouldGetNotifications &&
                    <PushNotifications
                        topic={BUDGET_TOPIC + this.state.selectedAccount.accountId + "-" + BudgetTypeTopic[this.state.budgetType.value]}
                        messageCallBack={(msg) => {
                            let newAccountStatus = this.state.accountStatus;
                            let state = {};
                            if (newAccountStatus == msg.account.accountId) {
                                let allReports = this.state.allReports;
                                allReports.push.apply(allReports, msg.rows);
                                state.openModal = true;
                                state.allReports = allReports;
                                state.progress = status.done;
                            }
                            this.setState({ ...state });
                        }}>
                    </PushNotifications>
                }
                <Card>
                    <Card.Header>
                        <Card.Title tag="h5" className="funnels">
                            Budget Tool
                        </Card.Title>
                        <InfoBar infoText={<span><b>Starting from 24/5/2024 this tool will only show reports. </b><br />The Budget Tool Display a table with calculated budget projections based on the end date for lifetime, midweek, and weekend ad sets.</span>} />
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            <div class="form-group" style={{ maxWidth: "600px" }}>
                                <Form.Group>
                                    <Label>Select An Account</Label>
                                    <AccountDropdown
                                        updateStateCallback={this.updateStateCallback}
                                        accountChanged={this.accountChanged}
                                        selectedAccount={this.state.selectedAccount}
                                        platform='FACEBOOK'
                                        itemActive={AccountIsActive.ACTIVE}
                                    />
                                </Form.Group>
                            </div>

                            {this.state.selectedAccount !== null &&
                                <>
                                    {
                                        <>
                                            <Form.Group>
                                                <Label>Budget Type</Label>
                                                <div class="form-group" style={{ width: "200px" }}>
                                                    <Select
                                                        options={budgetOptions}
                                                        value={this.state.budgetType}
                                                        id="budgetOptions"
                                                        name="budgetOptions"
                                                        placeholder="Select budget type..."
                                                        onChange={(value) => this.setState({ budgetType: value })}
                                                    />
                                                </div>
                                            </Form.Group>
                                            {this.state.budgetType !== null && <Button className="submit-btn" onClick={this.sendBudgetToQueue}>Run</Button>}
                                        </>
                                    }
                                </>
                            }
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        )
    }
}
export default connect((store) => {
    return {
        user: store.userContext.user
    }
})(BudgetTool)