import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
    Col,
    Container, Row
} from "react-bootstrap";
export const fieldsText = {
    bidAmount: "Bid Amount",
    budget: "Budget",
    isActive: "Status",
    dailyBudget: "Daily Budget"

}
export default class ConfirmationTableView extends React.PureComponent {

    constructor(props) {
        super(props);
        this.setFormColums = this.setFormColums.bind(this);
        this.setData = this.setData.bind(this);
        this.checkMajor = this.checkMajor.bind(this);
    }

    setFormColums(userActions, acceleratedDto) {

        let cols = [];
        let fieldTextsKeys = Object.keys(fieldsText);
        let changedKeys = Object.keys(userActions);
        let majorChange;
        changedKeys.forEach(element => {
            if (fieldTextsKeys.includes(element)) {
                majorChange = this.checkMajor(userActions[element], acceleratedDto[element]);
                cols.push({
                    dataField: "current_" + element, text: "Current " + fieldsText[element], sort: false, classes: "currentValue", headerStyle: { width: "75px" }
                },
                    {
                        dataField: element, text: "New " + fieldsText[element], sort: false, classes: majorChange ? "newValue major" : "newValue", headerStyle: { width: "75px" }
                    });
            }
        });


        return cols;
    }

    checkMajor(userAction, acceleratedDto) {
        let majorChange = false;
        if (typeof userAction !== 'boolean') {
            if (userAction / acceleratedDto >= 2) {
                majorChange = true
            }
        }
        return majorChange;
    }

    setData(userAction, acceleratedDto) {
        let data = [];
        let fieldTextsKeys = Object.keys(fieldsText);
        let changedKeys = Object.keys(userAction);
        const changedRow = {
            acceleratedAdsetId: userAction.acceleratedAdsetId,
        }
        changedKeys.forEach(element => {
            if (fieldTextsKeys.includes(element)) {
                changedRow['current_' + element] = acceleratedDto[element];
                changedRow[element] = userAction[element];
            }
        });
        data.push(changedRow);
        return data;
    }

    render() {
        return (
            <div id="ConfirmationTableView" className={'ConfirmationTableView ' + this.props.className}>
                {this.props.userActions.map(userAction => {
                    let currentAdset = this.props.adsets.find(adset => adset.acceleratedDto.adsetId === userAction.acceleratedAdsetId);
                    return (
                        <Container>
                            <Row>
                                <h4 color="light">{currentAdset.acceleratedDto.adsetName}</h4>
                            </Row>
                            <Row >
                                <Col xs lg="3" className="p-0">
                                    <BootstrapTable
                                        size="sm"
                                        bootstrap4
                                        keyField="acceleratedAdsetId"
                                        bordered={false}
                                        data={this.setData(userAction, currentAdset.acceleratedDto)}
                                        columns={this.setFormColums(userAction, currentAdset.acceleratedDto)}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    );
                })}
            </div>
        )
    }
}

