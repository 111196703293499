import { Formik } from "formik";
import React from 'react';
import { Button, Form, Row } from "react-bootstrap";
import * as Yup from "yup";
import DatePickerControl from "../../../components/formFields/DatePickerControl";

export default class InsertAbTestForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.sumbitForm = this.sumbitForm.bind(this);
    }

    sumbitForm(values) {
        this.props.submitCallback(values);
    }

    render() {
        return (
            <Formik
                initialValues={{
                    testId: '',
                    adsetId: '',
                    element: '',
                    funnelId: '',
                    startDate: null,
                    endDate: null,
                    isVisible: false,
                    winning: false
                }}
                validationSchema={Yup.object().shape({
                    testId: Yup.string().required("Test Id is required"),
                    adsetId: Yup.string().required("Adset ID is required"),
                    element: Yup.string().required("Element is requrired"),
                    funnelId: Yup.string().trim().matches(/^[a-z0-9]{4}$/, 'Funnel ID must include 4 lowercase letter or numbers').required("Valid funnel ID is required"),
                    startDate: Yup.date().required("Start date is required"),

                    endDate: Yup.date().nullable().min(Yup.ref('startDate'), "End Date should be later than the start date"),


                })}
                onSubmit={(values) => this.sumbitForm(values)}
            >
                {({ handleSubmit, handleChange, handleBlur, errors, touched, values }) => (


                    <Form onSubmit={handleSubmit}>
                        <Row className="gx-1 mt-3">
                            <Form.Group className="col gx-1">
                                <Form.Label htmlFor="testId">Test Id</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="testId" id="testId"
                                    value={values.testId}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    isInvalid={!!errors.testId && !!touched.testId}
                                />
                                {!!touched.testId &&
                                    <Form.Control.Feedback type="invalid">{errors.testId}</Form.Control.Feedback>
                                }
                            </Form.Group>

                            <Form.Group className="col gx-1">
                                <Form.Label htmlFor="adsetId">Adset Id</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="adsetId" id="adsetId"
                                    value={values.adsetId}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    isInvalid={!!errors.adsetId && !!touched.adsetId}
                                />
                                {!!touched.adsetId &&
                                    <Form.Control.Feedback type="invalid">{errors.adsetId}</Form.Control.Feedback>
                                }
                            </Form.Group>

                            <Form.Group className="col gx-1">
                                <Form.Label htmlFor="element">Element</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="element" id="element"
                                    value={values.element}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    isInvalid={!!errors.element && !!touched.element}
                                />
                                {!!touched.element &&
                                    <Form.Control.Feedback type="invalid">Element is required</Form.Control.Feedback>
                                }
                            </Form.Group>

                            <Form.Group className="col gx-1">
                                <Form.Label htmlFor="funnelId">Funnel ID </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="funnelId" id="funnelId"
                                    value={values.funnelId}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    isInvalid={!!errors.funnelId && !!touched.funnelId}
                                />

                                {!!touched.funnelId &&
                                    <Form.Control.Feedback type="invalid">{errors.funnelId}</Form.Control.Feedback>
                                }
                            </Form.Group>
                            <Form.Group className="col gx-1">
                                <Form.Label htmlFor='startDate'>Start Date</Form.Label>
                                <DatePickerControl
                                    name="startDate"
                                    value={values.startDate}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                    isInvalid={!!errors.startDate && !!touched.startDate}

                                />
                            </Form.Group>

                            <Form.Group className="col gx-1">
                                <Form.Label htmlFor='endDate'>End Date</Form.Label>
                                <DatePickerControl
                                    name="endDate"
                                    value={values.endDate}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                    isInvalid={!!errors.endDate && !!touched.endDate}

                                />
                            </Form.Group>
                            <Form.Group className="col gx-1" style={{ textAlign: 'center' }}>
                                <Form.Label htmlFor="isVisible">Is visible</Form.Label>
                                <Form.Check
                                    type="switch"
                                    name="isVisible" id="isVisible"
                                    value={values.isVisible}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    isInvalid={!!errors.isVisible && !!touched.isVisible}
                                />

                                {!!touched.isVisible &&
                                    <Form.Control.Feedback type="invalid">{errors.isVisible}</Form.Control.Feedback>
                                }
                            </Form.Group>
                            <Form.Group className="col gx-1"  style={{ textAlign: 'center' }}>
                                <Form.Label htmlFor="winning">Winning</Form.Label>
                                <Form.Check
                                    type="switch"
                                    name="winning" id="winning"
                                    value={values.winning}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    isInvalid={!!errors.winning && !!touched.winning}
                                />

                                {!!touched.winning &&
                                    <Form.Control.Feedback type="invalid">{errors.winning}</Form.Control.Feedback>
                                }
                            </Form.Group>
                            <Button type="submit" className="submit-btn col-auto align-self-start gx-1" variant="primary">Create Test</Button>
                        </Row>
                    </Form>
                )}
            </Formik>

        )
    }
}
