import React from "react";
import { Button, Card, Container, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from 'react-bootstrap-table2-editor';
import filterFactory from 'react-bootstrap-table2-filter';
import * as Icon from "react-feather";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import FacebookContextComponent from "../../../components/FacebookContextComponent";
import GenericErrorAlert from "../../../components/GenericErrorAlert";
import InfoBar from "../../../components/InfoBar";
import Loader from "../../../components/Loader";
import ProductDropdown from "../../../components/ProductDropdown";
import { genericFetcherFactory } from "../../../utils/requestUtils";
import AllowedAdDomainsEditForm from "./AllowedAdDomainsEditForm";


class AllowedAdDomains extends React.PureComponent {
    constructor(props) {
        super(props);

        this.update = this.update.bind(this);
        this.remove = this.remove.bind(this);
        this.productChanged = this.productChanged.bind(this);
        this.updateStateCallback = this.updateStateCallback.bind(this);
        this.revertRowChanges = this.revertRowChanges.bind(this);
        this.updateRows = this.updateRows.bind(this);

        this.state = {
            allowedAdDomainData: [],
            selectedProduct: null,
            initSuccess: null,
            addingNewRow: false,
            isNewButtonClicked: false,
            initState: [],
            pristineState: null


        };
    }

    componentDidMount() {

    }

    updateStateCallback(key, value) {
        this.setState({ [key]: value });
    }


    fetchExistingDocsByProductId(productId) {
        genericFetcherFactory(`/api/facebook-interface/AllowedDomains/ByProductId/${productId}`,
            "ALLOWED_AD_DOMAINS", "Failed to fetch AllowedDomains")().then(res => {
                if (res.success == true) {
                    if (res.data == null) {
                        toastr.info("Didn't find Documents for this product on DB");
                        res.data = [];
                    }
                    let allowedAdDomainDataWithpristineState = [];
                    res.data.forEach(data => {
                        data.pristineState = { ...data };
                        allowedAdDomainDataWithpristineState.push(data);
                    });

                    this.setState({ allowedAdDomainData: allowedAdDomainDataWithpristineState, formSaving: false });
                } else {
                    this.setState({ formSaving: false });
                }
            })
    }

    update(updateObject) {
        this.setState({ formSaving: true });
        updateObject.allowedDomainURL = updateObject.allowedDomainURL.trim();

        genericFetcherFactory(`/api/facebook-interface/AllowedDomains/${updateObject.id}`,
            "EDIT_DOMAIN",
            "Failed to update domain",
            "PATCH", {
            method: "PATCH",
            body: JSON.stringify(updateObject),
            headers: { 'Content-Type': 'application/json' }
        })().then(result => {
            if (result.success == true) {
                let updatedObject = result.data;
                let dataCopy = [...this.state.allowedAdDomainData];
                let rowIndex = dataCopy.findIndex(item => (item.id === updatedObject.id));
                if (rowIndex !== -1) {
                    // Save the original state
                    const originalData = dataCopy[rowIndex];
                    updatedObject.pristineState = { ...originalData };

                    dataCopy[rowIndex] = updatedObject;
                }
                this.setState({ allowedAdDomainData: dataCopy, formSaving: false });
            } else {
                this.setState({ formSaving: false });
            }
        });
    }


    remove(removeObject) {
        let confirmation = window.confirm("Are you sure you want to delete?");

        if (confirmation == true) {
            this.setState({ formSaving: true });

            genericFetcherFactory(`/api/facebook-interface/AllowedDomains/delete/${removeObject.id}`,
                "DELETE_DOMAINS", "Failed to delete domain", "DELETE",
                {
                    method: "DELETE"
                })().then(result => {
                    if (result.success == true) {
                        let dataCopy = [...this.state.allowedAdDomainData];
                        let rowIndex = dataCopy.findIndex(item => (item.id === removeObject.id));
                        dataCopy.splice(rowIndex, 1);
                        this.setState({ allowedAdDomainData: dataCopy, formSaving: false });
                    } else {
                        this.setState({ formSaving: false });
                    }
                });
        }
    }


    async productChanged(product) {
        if (product === undefined) {
            toastr.error("Selected product has no data. Please try to refresh the page and try again");
            this.setState({ initSuccess: false });
            return;
        }

        this.setState({ selectedProduct: product, initSuccess: true, isNewButtonClicked: false });
        this.fetchExistingDocsByProductId(product.productId);

    }


    columns = [
        {
            dataField: 'allowedDomainURL', text: 'Domain URL',
            validator: (newValue, row, column) => {
                if (!newValue) {
                    return {
                        valid: false,
                        message: 'URL is required'
                    };
                }
               
                return true;
            }
        },
        {
            editable: false, isDummyField: true, headerStyle: { width: "80px", textAlign: "center" }, align: "center",
            formatter: (cell, row) => this.actionsFormatter(
                cell, row, { remove: this.remove, update: this.update, revertRowChanges: this.revertRowChanges }
            )
        }
    ];

    revertRowChanges(rowItem) {
        let copyOfObj = [...this.state.allowedAdDomainData];
        let index = copyOfObj.findIndex(_allowedAdDomain => _allowedAdDomain.id == rowItem.id);
        if (index != -1) {
            let pristineCopy = { ...rowItem.pristineState };
            let pristineItem = { ...rowItem.pristineState };
            pristineItem.pristineState = pristineCopy;
            copyOfObj.splice(index, 1, pristineItem);
            this.setState({ allowedAdDomainData: copyOfObj });
        }
    }

    actionsFormatter(cell, row, { remove, update, revertRowChanges }) {
        let removeFormatterId = "deleteIcon-" + row.id;
        let saveFormatterId = "saveIcon-" + row.id;
        let undoFormatterId = "undoIcon-" + row.id;
        return (
            <div className="editButtons" tabIndex="-1">
                <span>
                    <OverlayTrigger placement="top" key={removeFormatterId} overlay={
                        <Tooltip placement="top" id={removeFormatterId}>
                            Delete Ad Domain
                        </Tooltip>
                    }>
                        <Icon.Trash2 id={removeFormatterId} style={{ cursor: "pointer" }} className="feather mr-2"
                            onClick={() => { remove(row) }} />

                    </OverlayTrigger>
                </span>
                <span>
                    <OverlayTrigger placement="top" key={saveFormatterId} overlay={
                        <Tooltip placement="top" id={saveFormatterId}>
                            {row.isChanged ? "Save" : "No changes made"}
                        </Tooltip>
                    }>
                        <Icon.Save id={saveFormatterId} style={{ cursor: "pointer" }} className="feather mr-2" color={row.isChanged ? "black" : "lightgrey"}
                            onClick={row.isChanged ? () => { update(row) } : null} />
                    </OverlayTrigger>
                </span>
                <span>
                    <OverlayTrigger placement="top" key={undoFormatterId} overlay={
                        <Tooltip placement="top" id={undoFormatterId}>
                            {row.isChanged ? "Undo Changes" : "No changes made"}
                        </Tooltip>
                    }>
                        <Icon.RotateCcw id={undoFormatterId} style={{ cursor: "pointer" }} className="feather mr-2" color={row.isChanged ? "black" : "lightgrey"}
                            onClick={row.isChanged ? () => { revertRowChanges(row) } : null} />
                    </OverlayTrigger>
                </span>
            </div>
        )
    }

    updateRows(allowedAdDomainData) {
        this.setState({ allowedAdDomainData: allowedAdDomainData });
    }

    cellSaved(oldValue, newValue, row, column, { state, updateRows }) {
        if (oldValue != newValue) {
            let allowedAdDomainData = state.allowedAdDomainData;
            let index = allowedAdDomainData.findIndex(test => test.id == row.id);
            if (index !== -1) {
                let newObj = { ...allowedAdDomainData[index], isChanged: true };
                allowedAdDomainData.splice(index, 1, newObj);
                updateRows(allowedAdDomainData);
            }
        }
    }

    render() {
        if (this.state.initSuccess === false) {
            this.setState({ formSaving: false });
            return (
                <GenericErrorAlert />
            );
        }

        return (
            <Container fluid className="p-0">
                <Modal show={this.state.formSaving === true} centered>
                    <Modal.Header>
                        Saving ...
                    </Modal.Header>
                    <Modal.Body className="text-center m-3">
                        <Loader />
                    </Modal.Body>
                </Modal>
                <Card>
                    <Card.Header>
                        <Card.Title tag="h5">
                            Allowed Ad Domains
                        </Card.Title>
                        <InfoBar infoText={"Manage Allowed Ad Domains for Facebook campagins."} />
                    </Card.Header>
                </Card>
                <Card >
                    {!this.props.facebookUserData.isLoggedIn &&
                        <Card.Body><Button color="info"><FacebookContextComponent shouldRender={true} /></Button></Card.Body>
                    }
                    {this.props.facebookUserData.isLoggedIn &&
                        <Card.Body >
                            <div className="mb-4" style={{ display: "flex", justifyContent: "space-between", zIndex: 1000, position: 'relative' }}>
                                <ProductDropdown
                                    widthClass={"col-12 col-lg-4"}
                                    updateStateCallback={this.updateStateCallback}
                                    productChanged={this.productChanged}
                                    selectedProduct={this.state.selectedProduct}
                                    fetchAllProducts={true}
                                />
                            </div>
                            {this.state.selectedProduct && <>
                                {this.state.allowedAdDomainData && this.state.allowedAdDomainData.length > 0 &&
                                    <BootstrapTable
                                        keyField="id" 
                                        // headerClasses=""
                                        bordered={false}
                                        striped
                                        hover
                                        data={this.state.allowedAdDomainData ? this.state.allowedAdDomainData : []}
                                        columns={this.columns}
                                        rowClasses={(row) => { return row.isChanged ? "row-pending-changes" : "" }}
                                        filter={filterFactory()}
                                        cellEdit={cellEditFactory({
                                            //edit allowed domain
                                            mode: 'click',
                                            blurToSave: true,
                                            afterSaveCell: (oldValue, newValue, row, column) =>
                                                this.cellSaved(oldValue, newValue, row, column,
                                                    {
                                                        state: { allowedAdDomainData: [...this.state.allowedAdDomainData] },
                                                        updateRows: this.updateRows
                                                    })
                                        })
                                        }
                                    />
                                }
                                {!this.state.isNewButtonClicked &&
                                    <Button className="btn btn-success" onClick={() => this.setState({ isNewButtonClicked: true })}>Add Domain</Button>
                                }

                                {this.state.isNewButtonClicked &&
                                    <AllowedAdDomainsEditForm
                                        selectedProduct={this.state.selectedProduct}
                                        allowedAdDomainData={this.state.allowedAdDomainData}
                                        updateStateCallback={this.updateStateCallback}
                                        customWidth = 'col-sm-6'
                                    />
                                }

                            </>
                            }
                        </Card.Body>
                    }
                </Card>
            </Container>
        )

    }

}


export default connect((store) => {
    return {
        facebookUserData: store.userContext.facebookUserData
    }
})(AllowedAdDomains)