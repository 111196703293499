import React from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody, CardHeader, CardTitle, Container, Spinner, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Info } from "react-feather";
import FacebookContextComponent from '../../../components/FacebookContextComponent';

import { genericFetcherFactory } from "../../../utils/requestUtils";
import { AccountIsActive } from "../../prods-and-accounts/account/accountUtils";
import GenericErrorAlert from "../../../components/GenericErrorAlert";

import StepWizard from 'react-step-wizard';
import Step1LoadPost from "./steps/Step1LoadPost";
import Step2ExpiredAdsets from "./steps/Step2ExpiredAdsets";
import Step3SwitchPosts from "./steps/Step3SwitchPosts";
import Step4ReviewAndRevert from "./steps/Step4ReviewAndRevert";
import PostSwitchToolWizardStepNavigation from "./PostSwitchToolWizardStepNavigation";
import AccountDropdown from "../../../components/AccountDropdown";
import InfoBar from "../../../components/InfoBar";
import MobileOnlyAlert from "../../../components/MobileOnlyAlert";

class FacebookPostSwitchTool extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            accounts: [],

            wizardInstance: null,

            stepData: {
                originalPostId: null,
                ...this.getCleanStepData()
            },

            lockInputs: false,
            isFetching: false,
            displayInfo: false,

            originalPostLoadErrorMessage: null,
            switchPostsErrorMessage: null
        };

        this.changeOriginalPost = this.changeOriginalPost.bind(this);
        this.updateExtendedAdsets = this.updateExtendedAdsets.bind(this);
        this.switchPosts = this.switchPosts.bind(this);
        this.updateRevertResult = this.updateRevertResult.bind(this);
        this.resetWizard = this.resetWizard.bind(this);
        this.accountChanged = this.accountChanged.bind(this);
        this.updateStateCallback = this.updateStateCallback.bind(this);

    }

    /**
     * Returns an object that contains empty step data except for the originalPostId property.
     */
    getCleanStepData() {
        return {
            expiredAdsets: null,
            extensionDate: null,
            extendedAdsets: null,
            switchResult: null,
            revertResult: null
        };
    }

    /** 
     * Returns a slice of properties from the stepData object relevant for the step specified in the step parameter
     */
    getStepData(stepData, step) {
        switch (step) {
            case 1:
                return {
                    originalPostId: stepData.originalPostId
                }
            case 2:
                return {
                    originalPostId: stepData.originalPostId,
                    extensionDate: stepData.extensionDate,
                    expiredAdsets: stepData.expiredAdsets,
                    extendedAdsets: stepData.extendedAdsets,
                }
            case 3:
                return {
                    originalPostId: stepData.originalPostId,
                    newPostId: stepData.newPostId
                }
            case 4:
                return {
                    originalPostId: stepData.originalPostId,
                    newPostId: stepData.newPostId,
                    switchResult: stepData.switchResult,
                    revertResult: stepData.revertResult
                }
            default:
                console.error(`Unknown step: ${step}`);
                return null;
        }
    }

    componentDidMount() {
    }


    updateStateCallback(key, value) {
        this.setState({ [key]: value });
    }

    getPostAccountId(postId) {
        return genericFetcherFactory(`/api/facebook-interface/PostSwitchTool/getPostFacebookAccountId/${postId}`, "FETCH_ACCOUNT_ID", "Fail to fetch Facebook Account ID for Post", "GET")()
    }

    loadPostData(postId) {
        return genericFetcherFactory(`/api/facebook-interface/PostSwitchTool/getExpiredAdsetsByPostId/${postId}`, "FETCH_ADSETS", "Fail to load post Adsets", "GET")()
    }

    accountChanged(account) {
        let confirmation = true;
        if (this.state.selectedAccount != null && this.state.stepData.originalPostId != null && this.state.stepData.expiredAdsets != null) {
            confirmation = window.confirm("Changing the account will reset the wizard. Are you sure?");
        }

        if (confirmation === true) {
            let selectedAccount = account;
            if (selectedAccount !== undefined) {
                if (this.state.wizardInstance && this.state.wizardInstance.currentStep != 1) {
                    this.state.wizardInstance.goToStep(1);
                }
                this.setState({ lockInputs: false, selectedAccount: selectedAccount, stepData: { ...this.state.stepData, ...this.getCleanStepData() } });
            }
        }
    }

    async changeOriginalPost(postId) {
        if (this.state.stepData.originalPostId == null || this.state.stepData.originalPostId === postId || window.confirm("Changing the post ID will reset the following steps. Proceed?")) {
            this.setState({ isFetching: true });

            let accountIdFetchResult = await this.getPostAccountId(postId);
            if (accountIdFetchResult.success === true) {
                let externalAccountId = accountIdFetchResult.data;
                if (this.state.selectedAccount.accountPlatformId == externalAccountId) {
                    this.loadPostData(postId).then(response => {
                        if (response.success == true) {
                            let expiredAdsets = response.data;

                            let newState = {
                                ...this.state,
                                isFetching: false,
                                originalPostLoadErrorMessage: null,
                                stepData: {
                                    ...this.getCleanStepData(),
                                    originalPostId: postId,
                                    expiredAdsets: expiredAdsets
                                }
                            };

                            this.setState(newState);
                            this.state.wizardInstance.nextStep();
                        } else {
                            this.setState({ isFetching: false });
                        }
                    })
                } else {
                    let errorMessage;
                    let maybeAccount = this.state.accounts.find(account => account.accountPlatformId == externalAccountId);

                    if (maybeAccount !== undefined) {
                        errorMessage = `Incorrect Account selected. The Post appears to belong to Account ID ${externalAccountId} which is ${maybeAccount.accountName}`;
                    } else {
                        errorMessage = `Incorrect Account selected. Account with Facebook Account ID ${externalAccountId} could not be found`;
                    }

                    this.setState({ isFetching: false, originalPostLoadErrorMessage: errorMessage });
                }
            } else {
                this.setState({ isFetching: false });
            }
        }
    }

    updateExtendedAdsets(extendedAdsets, extensionDate) {
        this.setState({
            stepData: {
                ...this.state.stepData,
                extendedAdsets: extendedAdsets,
                extensionDate: extensionDate
            }
        })
    }

    switchPosts(newPostId) {
        this.setState({ isFetching: true, stepData: { ...this.state.stepData, newPostId: newPostId } });

        genericFetcherFactory(`/api/facebook-interface/PostSwitchTool/switchPosts?originalPostId=${this.state.stepData.originalPostId}&newPostId=${newPostId}`, "SWITCH_POSTS", "Failed to switch posts", "POST", null)()
            .then(result => {
                if (result.success) {
                    let newState = {
                        ...this.state,
                        lockInputs: true,
                        isFetching: false,
                        switchPostsErrorMessage: null,
                        stepData: {
                            ...this.state.stepData,
                            switchResult: result.data
                        }
                    };

                    this.setState(newState);
                    this.state.wizardInstance.nextStep();
                } else {
                    this.setState({ isFetching: false, switchPostsErrorMessage: result.message });
                }
            });
    }

    updateRevertResult(revertResult) {
        this.setState({
            stepData: {
                ...this.state.stepData,
                revertResult: revertResult
            }
        })
    }

    resetWizard() {
        this.state.wizardInstance.goToStep(1);
        this.setState({
            stepData: {
                originalPostId: null,
                ...this.getCleanStepData()
            },
            lockInputs: false
        });
    }

    render() {
        let selectedAccount;
        if (this.state.initSuccess === true) {
            selectedAccount = this.state.selectedAccount;
        } else if (this.state.initSuccess === false) {
            return <GenericErrorAlert />
        }
        return (
            <Container fluid className="p-0 postSwitchTool">
                <Modal
                    size={"lg"}
                    isOpen={this.state.displayInfo === true}
                    centered
                >
                    <ModalHeader>                        
                        Post Switch Tool Information
                    </ModalHeader>
                    <Button color="secondary" size="small"
                        style={{ position: "absolute", top: "10px", right: "10px" }}
                        onClick={e => this.setState({ displayInfo: false })}>
                        Close
                    </Button>
                    <ModalBody>
                        <div className="pst-wizard-info">
                            <ul>
                                <li>
                                    <b>General Information</b>
                                    <ul>
                                        <li>The Ads and Adsets data for a Post relies on the Data Warehouse which is updated every 6 hours and <u><b>does not come directly from Facebook itself</b></u>.</li>
                                        <li>All operations will be performed on the data available from the Data Warehouse. If there were any new Ads created or updated in the past 6 hours please manage them manually.</li>
                                    </ul>
                                </li>
                                <li>
                                    <b>Step 1: Load Post</b>
                                    <ul>
                                        <li>Make sure the correct account is selected for the Post ID. Changing either will reset the wizard.</li>
                                    </ul>
                                </li>
                                <li>
                                    <b>Step 2: Manage Expired Adsets</b>
                                    <ul>
                                        <li><b>Adsets expiration extension cannot be undone.</b> However you can change the date and run the operation more than once.</li>
                                        <li>Select the date and time in UTC. The Date and time will be adjusted to the Account's timezone automatically.</li>
                                    </ul>
                                </li>
                                <li>
                                    <b>Step 3: Switch Posts</b>
                                    <ul>
                                        <li>The tool does not validate the new Post ID with Facebook and will attempt to switch with any value entered.</li>
                                    </ul>
                                </li>
                                <li>
                                    <b>Step 4: Review and Revert</b>
                                    <ul>
                                        <li>Once the Posts have been switched the wizard will lock all inputs and it will only be possible to attempt to revert the switch.</li>
                                        <li>It is possible to try and revert more than once in case the operation failed, however doing so will also try to revert Ads that have already been reverted.</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </ModalBody>
                </Modal >
                <Card>
                    <CardHeader>
                        <MobileOnlyAlert message={"Please rotate your mobile phone for better view"} />
                        <CardTitle tag="h5" className="mb-0">Post Switch Tool (Experimental)</CardTitle>
                        <InfoBar infoText={<span>Previously allowed removing a <b>Post ID</b> from the UI to enable post editing for ads.<br /><b>No longer relevant</b> as Facebook no longer supports text editing for existing posts.</span>} />
                    </CardHeader>
                    <CardBody>
                        {!this.props.facebookUserData.isLoggedIn &&
                            <Button color="info"><FacebookContextComponent shouldRender={true} /></Button>
                        }
                        {this.props.facebookUserData.isLoggedIn &&
                            <div>
                                <div className={"top-box"}>
                                    <AccountDropdown updateStateCallback={this.updateStateCallback} getAccounts={true} accountChanged={this.accountChanged} selectedAccount={this.state.selectedAccount} platform='FACEBOOK' itemActive={AccountIsActive.ALL} />

                                    <div className={"info-button"} onClick={() => this.setState({ displayInfo: true })}><Info size={38} color={"#FFA30F"} /></div>
                                </div>

                                {selectedAccount !== null &&
                                    <StepWizard
                                        transitions={{
                                            enterRight: 'pst-wizard-animate__fadeIn',
                                            enterLeft: 'pst-wizard-animate__fadeIn',
                                            exitRight: 'pst-wizard-animate__fadeOut',
                                            exitLeft: 'pst-wizard-animate__fadeOut'
                                        }}
                                        className={"wizard-wrap"}
                                        nav={<PostSwitchToolWizardStepNavigation stepData={this.state.stepData} />}
                                        instance={(instance) => this.setState({ wizardInstance: instance })}
                                        isLazyMount={true}>
                                        <Step1LoadPost stepData={this.getStepData(this.state.stepData, 1)} changePostId={this.changeOriginalPost} errorDisplay={this.state.originalPostLoadErrorMessage} readOnly={this.state.lockInputs} />
                                        <Step2ExpiredAdsets account={this.state.selectedAccount} stepData={this.getStepData(this.state.stepData, 2)} updateExtendedAdsets={this.updateExtendedAdsets} readOnly={this.state.lockInputs} />
                                        <Step3SwitchPosts stepData={this.getStepData(this.state.stepData, 3)} switchPosts={this.switchPosts} errorDisplay={this.state.SwitchPostsErrorMessage} readOnly={this.state.lockInputs} />
                                        <Step4ReviewAndRevert stepData={this.getStepData(this.state.stepData, 4)} resetWizard={this.resetWizard} updateRevertResult={this.updateRevertResult} />
                                    </StepWizard>
                                }
                                {this.state.isFetching &&
                                    <Spinner />
                                }
                            </div>
                        }
                    </CardBody>
                </Card>
            </Container >
        )

    }
}
export default connect((store) => {
    return {
        facebookUserData: store.userContext.facebookUserData
    }
})(FacebookPostSwitchTool)