import React from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Container,
    Modal,
    ModalBody,
    ModalHeader,
    Spinner
} from "reactstrap";
import { Badge } from "react-bootstrap";
import FacebookContextComponent from "../../components/FacebookContextComponent";
import GenericErrorAlert from "../../components/GenericErrorAlert";
import AccountDropdown from "../../components/AccountDropdown";
import { AccountIsActive } from "../prods-and-accounts/account/accountUtils";
import Loader from "../../components/Loader";
import { formStatus } from "../../utils/formUtils";
import { genericFetcherFactory } from "../../utils/requestUtils";
import AdsetDataView from "./components/adsetDataView/AdsetDataView";
import ConfirmationTableView from "./components/ConfirmationTableView";
import MediaQuery from 'react-responsive';
import { acceleratedAdsetsPageColumnsToHide,acceleratedAdsetsPageColumnsOrder } from "./components/adsetDataView/adsetDataConstants";
import InfoBar from "../../components/InfoBar";
import MobileOnlyAlert from "../../components/MobileOnlyAlert";

class AcceleratedAdsetsTool extends React.PureComponent {
    constructor(props) {
        super(props);
        this.handleAdsetActions = this.handleAdsetActions.bind(this);
        this.saveAdsetActions = this.saveAdsetActions.bind(this);
        this.accountChanged = this.accountChanged.bind(this);
        this.state = {
            accountPlatformId: this.props.match.params.accountPlatformId,
            accountId: this.props.match.params.accountId,
            productId: this.props.match.params.productId,
            selectedAccount: null,
            adsets: null,
            userChanges: null,
            init: true,
            savingAdsets: formStatus.IDLE,
            formConfirmed: null,
            lastUpdateBE: "N/A",
            lastUpdateFB: "N/A"
        }
    }

    setStatusByEnum(adsetStatus) {
        if (adsetStatus !== 'PAUSED') {
            return true;
        } else {
            return false;
        }
    }

    componentDidMount() {
        if (!this.props.match.params.accountId.startsWith(":") && !this.props.match.params.productId.startsWith(":")) {
            this.setState({ accountId: this.props.match.params.accountId, productId: this.props.match.params.productId });
            this.fetchNecessaryData(this.props.match.params.accountId, this.props.match.params.accountPlatformId, this.props.match.params.productId);
        }
    }

    async accountChanged(account) {
        if (account !== undefined) {
            this.setState({
                selectedAccount: account, accountId: account.accountId, init: null, adsets: null,
                accountPlatformId: account.accountPlatformId, lastUpdateBE: null, lastUpdateFB: null
            });
            this.fetchNecessaryData(account.accountId, account.accountPlatformId);
        }
    }

    async fetchAcceleratedAdsets(accountId, accountPlatformId, productId, productName) {
        genericFetcherFactory("/Aggregator/dailyAcceleratedAggregator", "Daily", "Failed to get Adsets", "POST",
            {
                method: "POST",
                body: JSON.stringify({
                    accountId: accountId,
                    accountPlatformId: accountPlatformId,
                    productId: productId,
                    pacingType: "no_pacing",
                    payloadType: "dailyAcceleratedAggregator"
                }),
                headers: { "Content-Type": "application/json" }
            })().then((res) => {
                if (res.success && res.data.lambdaJsonResponse.length > 0) {
                   
                    let dailyAcceleraedList = res.data.dailyAcceleraedList;
                    let lambdaJsonResponse = JSON.parse(res.data.lambdaJsonResponse);
                    this.setBadgeData(lambdaJsonResponse);
                    
                    if (lambdaJsonResponse.length == 0) {
                        this.setState({ init: false, errorMessage: "No data from Snowflake for this account" });
                        return;
                    }
                    
                    let adsets = [];
                    dailyAcceleraedList.forEach((acc) => {
                        acc.isActive = this.setStatusByEnum(acc.adsetStatus);
                        let sfAdsetRow = lambdaJsonResponse.filter(row => acc.adsetId === row.adsetId);
                        acc.bidAmount /= 100;// convert cent to dollar
                        acc.dailyBudget /= 100;
                        // acc.budgetRemaing /= 100;
                        if (!acc.isActive && sfAdsetRow[0] !== undefined && sfAdsetRow[0]['Spend Today'] < 1) {
                            return;
                        } else {
                            let adset = {
                                acceleratedDto: acc,
                                adsetData: sfAdsetRow
                            }
                            adsets.push(adset)
                        }
                    })

                    if (adsets.length > 0) {
                        this.setState({ init: true, adsets: adsets, productName: productName });
                    } else {
                        this.setState({ init: false, errorMessage: "Failed to get data for accelerated adsets" });

                    }
                } else {
                    this.setState({ init: false, errorMessage: "No accelerated adsets in DB for this account" });
                }
            });
    }

    setBadgeData(lambdaJsonResponse) {
        let lastUpdateFacebook = lambdaJsonResponse.find(row => row['FB Last Update IL']);
        let lastUpdateBERow = lambdaJsonResponse.find(row => row['BE last Update IL']);

        let lastUpdateFB = "N/A";
        let lastUpdateBE = "N/A";

        if (lastUpdateFacebook !== undefined) {
            lastUpdateFB = lastUpdateFacebook['FB Last Update IL'];
        }
        if (lastUpdateBERow !== undefined) {
            lastUpdateBE = lastUpdateBERow['BE last Update IL'];
        }

        this.setState({ lastUpdateFB: lastUpdateFB, lastUpdateBE: lastUpdateBE });
    }

    async fetchNecessaryData(accountId, accountPlatformId, productId) {
        if (productId !== undefined) {
            genericFetcherFactory("/api/product/Products/Product/" + productId, "Daily", "Failed to get Adsets", "GET")().then((res) => {
                let productName = res.data.productName;
                this.fetchAcceleratedAdsets(accountId, accountPlatformId, productId, productName);
            })
        } else {
            this.fetchAcceleratedAdsets(accountId, accountPlatformId);
        }
    }

    handleAdsetActions(userChanges) {
        if (userChanges.length <= 0) {
            toastr.warn("No pending changes to save");
        } else {

            this.setState({ savingAdsets: formStatus.CONFIRMATION_REQUIRED });
            if (this.state.formConfirmed === null) {
                this.setState({ savingAdsets: formStatus.CONFIRMATION_REQUIRED, userChanges: userChanges });
            } else {
                this.saveAdsetActions(userChanges);
            }
        }
    }

    saveAdsetActions(userChanges) {
        const newAdsets = [...this.state.adsets];
        let userChangeInDollar = [];
        userChanges.forEach(acc => {
            let keys = Object.keys(acc);
            keys.forEach(key => {
                if (key == "bidAmount" || key == "dailyBudget") {
                    acc[key] *= 100;
                }
            });
            //add snowflake data to dto
            let index = newAdsets.findIndex(adset => adset.acceleratedDto.adsetId == acc.acceleratedAdsetId);
            if (index !== -1) {
                let adset = newAdsets[index];
                acc.snowflakeData = adset.adsetData.length == 0 ? null : adset.adsetData[0];
            }

            userChangeInDollar.push(acc);
        });

        this.setState({ savingAdsets: formStatus.IN_PROGRESS, adsets: null });
        genericFetcherFactory(`/api/facebook-interface/DailyAccelerated/updateAll`, "UPDATE_ADSETS", "Failed to save all adsets", "POST",
            {
                method: "POST",
                body: JSON.stringify(userChangeInDollar),
                headers: {
                    "Content-Type": "application/json"

                }
            })().then(result => {
                if (result.success === true) {
                    let updatedRows = result.data;
                    let failedToReplace = [];

                    updatedRows.forEach(row => {
                        let index = newAdsets.findIndex(adset => adset.acceleratedDto.adsetId == row.acceleratedAdsetId);
                        if (index !== -1) {
                            let adset = newAdsets[index];
                            //update dto object
                            let dto = adset.acceleratedDto;
                            dto.bidAmount = row.bidAmount / 100;
                            dto.dailyBudget = row.dailyBudget / 100;
                            dto.isActive = row.isActive;
                            newAdsets.splice(index, 1, adset);
                        }
                    });

                    if (failedToReplace.size > 0) {
                        toastr.warn("The view could not be properly updated. Check the console for details and please refresh the page to avoid inconsistency.");
                        console.log(failedToReplace);
                    }
                    //saved succesed
                    this.setState({ adsets: newAdsets, savingAdsets: formStatus.IDLE });

                }
            });
    }

    render() {
        return (
            <Container fluid className="p-0" id="AcceleratedAdsetsTool">
                <Modal size="xl"
                    isOpen={this.state.savingAdsets !== formStatus.IDLE}
                    centered
                >
                    <ModalHeader tag="h2" toggle={() => this.setState({ savingAdsets: formStatus.IDLE })}>
                        Update Adsets {this.state.savingAdsets}
                    </ModalHeader>
                    <ModalBody>
                        {this.state.savingAdsets === formStatus.IN_PROGRESS &&
                            <Spinner color="dark" className="mr-2" />
                        }
                        {this.state.savingAdsets === formStatus.CONFIRMATION_REQUIRED &&
                            <>
                                <ConfirmationTableView
                                    userActions={this.state.userChanges}
                                    adsets={this.state.adsets}
                                />
                                <Button onClick={() => this.saveAdsetActions(this.state.userChanges)} color="primary">Confirm Changes</Button>
                            </>
                        }
                    </ModalBody>
                </Modal>


                <Card>
                    <CardHeader className="pb-1">
                        <MobileOnlyAlert message={"Please rotate your mobile phone for better view"} />
                        <CardTitle tag="h1" className="mb-0">Accelerated Adset Management <b>{this.state.productName}</b></CardTitle>
                        <InfoBar infoText={"Adjust bids and budgets for daily accelerated ad sets."} />
                    </CardHeader>
                    <CardBody className="pt-0">
                        {!this.props.facebookUserData.isLoggedIn ?
                            <Button color="info"><FacebookContextComponent shouldRender={true} /></Button>
                            :
                            <>
                                <div className="pb-2">
                                    {this.state.selectedAccount !== null &&
                                        <>
                                            <Badge bg="warning" className="me-2">BE Update: {this.state.lastUpdateBE}</Badge>
                                            <Badge bg="warning" className="me-2">FB Update: {this.state.lastUpdateFB}</Badge>
                                        </>
                                    }
                                </div>
                                {this.props.match.params.accountId.startsWith(":") &&
                                    this.props.match.params.accountId.startsWith(":") &&
                                    <div className="mb-4" style={{ display: "flex", justifyContent: "space-between", zIndex: 1, position: 'relative' }}>
                                        <AccountDropdown
                                            widthClass={"col-12 col-sm-4"}
                                            accountChanged={this.accountChanged}
                                            selectedAccount={this.state.selectedAccount}
                                            updateStateCallback={(params) => { console.log(params) }}
                                            platform='FACEBOOK'
                                            itemActive={AccountIsActive.ACTIVE} />
                                    </div>
                                }
                                {this.state.init === null ?
                                    <Loader />
                                    :
                                    <>
                                        {this.state.adsets !== null &&
                                            <>
                                                <MediaQuery minWidth={900} >
                                                    <AdsetDataView
                                                        adsets={this.state.adsets}
                                                        type="acclerated"
                                                        submitFunction={this.handleAdsetActions}
                                                        hiddenColumns={acceleratedAdsetsPageColumnsToHide}
                                                         reorderColumns={acceleratedAdsetsPageColumnsOrder}

                                                    />
                                                </MediaQuery>
                                                <MediaQuery maxWidth={899} >
                                                    <AdsetDataView
                                                        adsets={this.state.adsets}
                                                        type="acclerated"
                                                        submitFunction={this.handleAdsetActions}
                                                        hideColumnsOnMobile={true}
                                                        hiddenColumns={acceleratedAdsetsPageColumnsToHide}
                                                        reorderColumns={acceleratedAdsetsPageColumnsOrder}
                                                    />
                                                </MediaQuery>
                                            </>
                                        }
                                        {this.state.init === false &&
                                            <GenericErrorAlert errorMessage={this.state.errorMessage} />
                                        }
                                    </>
                                }
                            </>
                        }
                    </CardBody>
                </Card>
            </Container >
        )
    }
}

export default connect((store) => {
    return {
        facebookUserData: store.userContext.facebookUserData
    }
})(AcceleratedAdsetsTool)
