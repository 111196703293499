import React from "react";
import { connect } from 'react-redux';
import Select from "react-select";
import { setProductData } from "../redux/actions/userContextActions";
import { CACHE_EXPIRATION_DEFAULTS } from "../services/qsCache";
import { addHours } from "../utils/dateUtils";
import { genericCachedFetcherFactory } from "../utils/requestUtils";
class ProductDropdown extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            initSuccess: null,
            products: [],
        }

        this.productChanged = this.productChanged.bind(this);
    }

    // Init and fetchers

    componentDidMount() {
        if (this.props.fetchAllProducts) {
            this.fetchAllProducts();
        } else {
            this.fetchProductsForBrandId();
        }

    }

    async componentDidUpdate(prevProps) {
        if (this.props.allProducts == undefined && prevProps.brandId !== this.props.brandId) { //TODO check 
            this.fetchProductsForBrandId();
        }
    }

    async fetchAllProducts() {
        genericCachedFetcherFactory(`/api/product/Products`, "PRODUCTS", "Failed to fetch products", addHours(CACHE_EXPIRATION_DEFAULTS.Accounts))().then((result) => {
            if (result.success === true) {
                if (this.props.products) {
                    // products list was already loaded 
                    this.setState({ initSuccess: true, products: this.props.products.data });
                } else {
                    this.setState({ initSuccess: true, products: [...result.data] });
                    //set in props
                    this.props.setProductData({ data: [...result.data] });

                    this.props.updateStateCallback('initSuccess', true);
                    if (this.props.getProducts) {
                        this.props.updateStateCallback('products', [...result.data]);
                    }
                }
            } else {
                this.setState({ initSuccess: false });
                this.props.updateStateCallback('initSuccess', false);
            }
        });
    }


    async fetchProductsForBrandId() {
        genericCachedFetcherFactory(`/api/product/Products/Brand/` + this.props.brandId, "PRODUCTS", "Failed to fetch products", addHours(CACHE_EXPIRATION_DEFAULTS.Accounts))().then((result) => {
            if (result.success === true) {
                if (this.props.products) {
                    // products list was already loaded 
                    this.setState({ initSuccess: true, products: this.props.products.data });
                } else {
                    this.setState({ initSuccess: true, products: [...result.data] });
                    //set in props
                    this.props.setProductData({ data: [...result.data] });

                    this.props.updateStateCallback('initSuccess', true);
                    if (this.props.getProducts) {
                        this.props.updateStateCallback('products', [...result.data]);
                    }
                }
            } else {
                this.setState({ initSuccess: false });
                this.props.updateStateCallback('initSuccess', false);
            }
        });
    }

    productChanged(productId) {
        let selectedProduct = this.state.products.find(item => item.productId === productId);
        this.props.productChanged(selectedProduct);
    }

    render() {
        if (this.state.initSuccess === true) {
            let productsSelectOptions = this.state.products.map(product => { return { value: product.productId, label: product.productName } });
            let selectedProduct = this.props.selectedProduct ? productsSelectOptions.find(option => option.value === this.props.selectedProduct.productId) : null;
            if (selectedProduct === undefined) {
                selectedProduct = null;
            }

            return (
                <Select
                    className={this.props.widthClass}
                    options={this.state.products.map(product => { return { value: product.productId, label: product.productName } })}
                    id="productDropdown"
                    placeholder="Select a product..."
                    value={selectedProduct}
                    onChange={selectedOption => this.productChanged(parseInt(selectedOption.value))}
                />
            )
        } else {
            return null;
        }
    }
}
export default connect(
    (store) => {
        return {
            products: store.userContext.products
        }
    },
    { setProductData })(ProductDropdown) 