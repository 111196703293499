import { Formik } from "formik";
import React from 'react';
import { Button, Form, Row } from "react-bootstrap";
import * as Yup from "yup";
import { genericFetcherFactory } from "../../../utils/requestUtils";

export const FormModes = {
    NEW: "new",
    EDIT: "edit"
};

export default class AllowedAdDomainsEditForm extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            allowedAdDomainData: this.props.allowedAdDomainData,
            initSuccess: null,
            isNewButtonClicked: false,
            formSaving: false
        };

        this.submitForm = this.submitForm.bind(this);
        this.addNew = this.addNew.bind(this);
    }

    componentDidMount() {
    }

    addNew(insertObject) {
        this.setState({ formSaving: true });

        genericFetcherFactory(`/api/facebook-interface/AllowedDomains`, "SAVE_DOMAINS", "Failed to save ad domain", "POST",
            {
                method: "POST",
                body: JSON.stringify(insertObject),
                headers: { 'Content-Type': 'application/json' }
            })().then(result => {
                if (result.success == true) {
                    if (this.props.usedAsModal) {
                        this.props.updateStateCallback('domainsModal', false);
                    }else{
                        let dataCopy = [...this.state.allowedAdDomainData];
                        dataCopy.push(result.data);
                        this.props.updateStateCallback('allowedAdDomainData', dataCopy);
                        this.props.updateStateCallback('formSaving', false);
                        this.props.updateStateCallback('isNewButtonClicked', false);
                    }
                } else {
                    this.setState({ formSaving: false });
                }
            });
    }

    submitForm(values) {
        (async () => {
            let newAllowedAdDomainData = {
                productId: this.props.selectedProduct.productId,
                productName: this.props.selectedProduct.productName,
                allowedDomainURL: values.allowedDomainURL.trim(),
                brandId: this.props.selectedProduct.brandId,
                user: "testuserT" 

            }
            this.addNew(newAllowedAdDomainData);

        })();
    }

    render() {
        return (
            <div>
                <Formik
                    initialValues={this.props.data}
                    validationSchema={Yup.object().shape({
                        allowedDomainURL: Yup.string().required("URL is required"),
                    })}
                    onSubmit={(values) => this.submitForm(values)}
                >
                    {({ handleSubmit, handleChange, handleBlur, errors, touched, values }) => (
                        <Form onSubmit={handleSubmit}>
                            <Row className="gx-1 mt-3">
                                <Form.Group className={ this.props.customWidth +' ' + "col gx-1"}>
                                    <Form.Label htmlFor="allowedDomainURL">Domain URL</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="allowedDomainURL"
                                        id="allowedDomainURL"
                                        value={values.allowedDomainURL}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={!!errors.allowedDomainURL && !!touched.allowedDomainURL}
                                    />
                                    {!!touched.allowedDomainURL &&
                                        <Form.Control.Feedback type="invalid">{errors.allowedDomainURL}</Form.Control.Feedback>
                                    }
                                </Form.Group>

                                <Button type="submit" className="submit-btn col-auto align-self-start gx-1" variant="primary">Add</Button>

                            </Row>
                        </Form>
                    )}
                </Formik>
            </div>
        )
    }
}

AllowedAdDomainsEditForm.defaultProps = {
    data: {
        productId: null,
        productName: null,
        allowedDomainURL: null,
        brandId: null,
        user: null
    },
    mode: FormModes.NEW,
    onSubmit: () => { }
}