import React from "react";
import {
    Badge, Button,
    Card,
    Container,
    Modal
} from "react-bootstrap";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import MediaQuery from 'react-responsive';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import AccountDropdown from "../../../components/AccountDropdown";
import { clientType } from "../../../components/AccountDropdown";
import FacebookContextComponent from "../../../components/FacebookContextComponent";
import { StoplossGHOnlyColumnsToHide, adsetFieldMapping } from "../components/adsetDataView/adsetDataConstants";
import GenericErrorAlert from "../../../components/GenericErrorAlert";
import Loader from "../../../components/Loader";
import InfoBar from "../../../components/InfoBar";
import { formStatus } from "../../../utils/formUtils";
import { genericFetcherFactory } from "../../../utils/requestUtils";
import { AccountIsActive } from "../../prods-and-accounts/account/accountUtils";
import * as adsetUtils from "../../../utils/adsetUtils"
import AdsetDataView from "../components/adsetDataView/AdsetDataView";
import { currencyFormatter, percentFormatter } from "../components/adsetDataView/AdsetViewHelper";
import ConfirmationTableView from "../components/ConfirmationTableView";
import MobileOnlyAlert from "../../../components/MobileOnlyAlert";

const totalsInit = {
    spend: 0, rev: 0, spendLastHour: 0, conversions: 0, roi: 0, roiLastHour: 0, spendRemainingForToday: 0, totalTestSpentToday: 0, testRoi: 0, testSpend: 0, testRev: 0, sales: 0
}

const CAMPAIGN_BUDGET_LEVEL = "Campaign";

class StoplossTool extends React.PureComponent {
    constructor(props) {
        super(props);
        this.handleAdsetActions = this.handleAdsetActions.bind(this);
        this.saveAdsetActions = this.saveAdsetActions.bind(this);
        this.accountChanged = this.accountChanged.bind(this);
        this.state = {
            accountId: this.props.match.params.accountId,
            adsets: null,
            userChanges: null,
            initAdsets: null,
            errorMessage: null,
            init: true,
            adsetDataInProcess: false,
            savingAdsets: formStatus.IDLE,
            formConfirmed: null,
            selectedAccount: null,
            lastUpdate: "N/A",
            UpdateroiLastHour: "N/A",
            filterProduct: [],
            totals: {},
            isCampaignView: false,
            isGHOnly: true
        }
    }

    getUiEdittedAdsetValues(adsetList, adsetRow, isDailyAdset) {
        const CENTS_IN_A_DOLLAR = 100;
        let adset = {};
        let adsetStatus = "PAUSED", adsetBudget, adsetBid;
        let maybeAdsetDto = adsetList.find(adset => adset.adsetId === adsetRow.adsetId);
        let dailyBudgetFieldName = adsetRow.budget_level === CAMPAIGN_BUDGET_LEVEL ? "campaign_daily_budget" : "dailyBudget";
        let lifetimeBudgetFieldName = adsetRow.budget_level === CAMPAIGN_BUDGET_LEVEL ? "campaign_lifetime_budget" : "Adset Budget";

        if (maybeAdsetDto !== undefined) {
            adsetStatus = maybeAdsetDto.adsetStatus;
            adsetBid = maybeAdsetDto.bidAmount / CENTS_IN_A_DOLLAR;
            adsetBudget = isDailyAdset ? maybeAdsetDto.dailyBudget : maybeAdsetDto.lifetimeBudget;
        }

        adset.isActive = adsetUtils.getStatusByEnum(adsetStatus);
        adset.bidAmount = adsetBid ? adsetBid : adsetRow.Bid;
        adset.budget = (adsetBudget ? adsetBudget : (isDailyAdset ? adsetRow[dailyBudgetFieldName] : adsetRow[lifetimeBudgetFieldName]));
        if (adsetRow.budget_level !== CAMPAIGN_BUDGET_LEVEL) {
            adset.budget /= CENTS_IN_A_DOLLAR;
        }

        return adset;
    }

    handleClientChange = (event) => {
        this.setState({ isGHOnly: event.target.value === "GHOnly", init: true, errorMessage: null, initAdsets: null, selectedAccount: null, adsets: null, lastUpdate: null, lastUpdateFB: null, totals: totalsInit });
    };

    handleDataTypeChange = (event) => {
        this.setState({ isCampaignView: event.target.value === "campaign", init: true, errorMessage: null, initAdsets: null, selectedAccount: null, adsets: null, lastUpdate: null, lastUpdateFB: null, totals: totalsInit });
    };

    getUnifiedadset(fieldMapping, adsetOrCampaignObject) {
        let unifiedAdset = {};
        for (let key in adsetOrCampaignObject) {
            if (fieldMapping.hasOwnProperty(key)) {
                unifiedAdset[fieldMapping[key]] = adsetOrCampaignObject[key];
            } else {
                unifiedAdset[key] = adsetOrCampaignObject[key]; // retain the original field if no mapping is found
            }
        }
        return unifiedAdset;
    }

    setCampaignDataAsAdsetData(adsetOrCampaignObject) {
        if (adsetOrCampaignObject.budget_level === CAMPAIGN_BUDGET_LEVEL) {
            //make adset values same as campaign values
            adsetOrCampaignObject["adsetId"] = adsetOrCampaignObject["campaign_id"];
            adsetOrCampaignObject["adsetName"] = adsetOrCampaignObject["campaign_name"];
            adsetOrCampaignObject["dailyBudget"] = adsetOrCampaignObject["campaign_daily_budget"];
            adsetOrCampaignObject["budgetRemaing"] = adsetOrCampaignObject["campaign_budget_remaining"];
            adsetOrCampaignObject["Adset Budget"] = adsetOrCampaignObject["campaign_lifetime_budget"];
        }
        return adsetOrCampaignObject;
    }

    aggregateAdsetData(adsetData, adsetRow) {
        const keysToAggregate = [
            'Spend Today', 'Conv Today', 'Sales BE Today', 'Revenue BE Today',
            'Spend -1 hour', 'Bid', 'Conv -1 Day', 'initiate_checkout_today', 'new_customers_today',
            'Revenue BE -1 Day', 'Sales BE -1 Day', 'Spend -1 Day now', 'Spend -1 Day -1 hour',
            'revenue_be_yesterday_now', 'Spend -7 Days -1 hour', 'revenue_be_7day_now', 'revenue_be_7day',
            'sales_be_today_for_ncr', 'conv_7day', 'clicks_7day', 'impressions_7day', 'spend_7day',
            'conv_1h_7day', 'clicks_1h_7day', 'impressions_1h_7day', 'spend_1h_7day', 'revenue_be_1h_7day',
            'sales_be_1h_7day', 'impressions_yesterday', 'clicks_yesterday', 'spend_yesterday',
            'Conv -1 Day Now', 'Conv -1 hour', 'clicks_today', 'impressions_today'
        ];

        // Helper function to sum numeric values
        const sumField = (key) => {
            adsetData[key] = Number(adsetData[key] || 0) + Number(adsetRow[key] || 0);
        };

        // Iterate over each key and sum the values
        keysToAggregate.forEach(sumField);
    }

    async accountChanged(account) {

        if (account !== undefined) {
            this.setState({ selectedAccount: account, adsetDataInProcess: true, adsets: null, lastUpdate: null, lastUpdateFB: null, totals: totalsInit });
            let adsetResponse = [];
            let lastUpdateDataFieldString = !this.state.isGHOnly ? 'BE last Update IL' : 'Last Update IL';
            [adsetResponse] = await Promise.all([this.fetchAdsetsByAccounts(account, this.state.isCampaignView, this.state.isGHOnly)]);
            if (adsetResponse.success === true) {
                const CENTS_IN_A_DOLLAR = 100;
                let totals = { ...totalsInit }
                let adsetList = adsetResponse.data.dailyAcceleraedList;
                let lambdaJsonResponse = JSON.parse(adsetResponse.data.lambdaJsonResponse);
                let lastUpdateRow = lambdaJsonResponse.find(row => row[lastUpdateDataFieldString]);
                let lastUpdateFacebookRow = lambdaJsonResponse.find(row => row['FB Last Update IL']);
                let commissionRow = lambdaJsonResponse.find(row => row['commission']);
                let commission = 1;
                if (commissionRow !== undefined) {
                    commission = commissionRow.commission;
                }

                let lastUpdate = "N/A";
                let lastUpdateFB = "N/A";
                if (lastUpdateRow !== undefined) {
                    lastUpdate = lastUpdateRow[lastUpdateDataFieldString];
                }
                if (lastUpdateFacebookRow !== undefined) {
                    lastUpdateFB = lastUpdateFacebookRow['FB Last Update IL'];
                }

                if (lambdaJsonResponse.length > 0) {
                    let adsets = [], adsetIds = [];

                    lambdaJsonResponse.forEach((responseAdsetRow) => {
                        let adsetRow = this.getUnifiedadset(adsetFieldMapping, responseAdsetRow);
                        let isDailyAdset = adsetUtils.isDailyAdset(adsetRow.adset_schedule_days);
                        let adsetDto = this.getUiEdittedAdsetValues(adsetList, adsetRow, isDailyAdset);
                        // adsetRow = this.setCampaignDataAsAdsetData(adsetRow);
                        adsetRow = adsetUtils.fillAdsetCalculatedFields(adsetRow);

                        adsetDto.adsetId = adsetRow.adsetId;
                        adsetDto.adsetName = adsetRow.adsetName;

                        if (adsetRow['Spend Today'] !== undefined) {
                            totals.spend += Number(adsetRow['Spend Today']);
                            if (adsetUtils.isTestAdset(adsetDto.adsetName)) {
                                totals.testSpend += Number(adsetRow['Spend Today']);
                            }
                        }
                        if (adsetRow['Conv Today'] !== undefined) {
                            totals.conversions += Number(adsetRow['Conv Today']);
                        }
                        if (adsetRow['Sales BE Today'] !== undefined) {
                            totals.sales += Number(adsetRow['Sales BE Today']);
                        }
                        if (adsetRow['Revenue BE Today'] !== undefined) {
                            totals.rev += Number(adsetRow['Revenue BE Today']);
                            if (adsetUtils.isTestAdset(adsetDto.adsetName)) {
                                totals.testRev += Number(adsetRow['Revenue BE Today']);
                            }
                        }
                        if (adsetRow['Spend -1 hour'] !== undefined) {
                            totals.spendLastHour += Number(adsetRow['Spend -1 hour']);
                        }
                        if (isDailyAdset) {
                            if (adsetRow.dailyBudget !== undefined) {
                                adsetDto.dailyBudget = adsetRow.dailyBudget = adsetDto.budget;
                            }
                        } else {
                            if (adsetRow['Adset Budget'] !== undefined) {
                                let calculatedDaysLeftToRun = adsetUtils.calculateDaysRemaining(adsetRow.adset_schedule_days, adsetRow.adset_end_time);
                                adsetDto.dailyBudget = adsetRow.dailyBudget = Math.round((Number(adsetRow.budgetRemaing) / calculatedDaysLeftToRun / CENTS_IN_A_DOLLAR + Number.EPSILON) * 100) / 100;
                            }
                        }
                        if (adsetRow.budgetRemaing !== undefined) {
                            adsetDto.budgetRemaining = adsetRow.budgetRemaing / CENTS_IN_A_DOLLAR;
                            adsetRow.budgetRemaing = Number(adsetRow.dailyBudget) - Number(adsetRow['Spend Today']);
                            if (adsetDto.isActive && adsetUtils.shouldAdsetRunToday(adsetRow.adset_schedule_days)) {
                                totals.spendRemainingForToday += Number(adsetRow.budgetRemaing);
                            }
                        }
                        if (adsetRow['Spend Today'] !== undefined && adsetUtils.isTestAdset(adsetDto.adsetName)) {
                            totals.totalTestSpentToday += Number(adsetRow['Spend Today'])
                        }

                        let adset = {
                            acceleratedDto: adsetDto,
                            adsetData: adsetRow
                        }
                        if (/* !adsetIds.includes(adsetRow.adsetId) && */ adsetRow.adsetId && adsetRow['Spend Today'] > 0) {
                            if (this.state.isCampaignView) {//show only campaigns 
                                if (adsetRow.budget_level === CAMPAIGN_BUDGET_LEVEL) {
                                    //show also adsets
                                    // adsets.push(adset);
                                    // //log adset and campaign IDs
                                    // console.log("adsetRow.adsetId: ", adsetRow.adsetId, "adsetRow.campaign_id: ", adsetRow.campaign_id, "Bid: ", adsetRow.Bid);
                                    let index = adsets.findIndex(adset => adset.adsetData.adsetId === adsetRow.campaign_id);
                                    if (index === -1) {
                                        let campaignAdset = JSON.parse(JSON.stringify(adset));
                                        let maybeCampaignDto = adsetResponse.data.dailyAcceleraedList.find(adset => adset.adsetId === adsetRow.campaign_id);
                                        this.setCampaignDataAsAdsetData(campaignAdset.adsetData);
                                        campaignAdset.acceleratedDto.budget_level = CAMPAIGN_BUDGET_LEVEL;
                                        campaignAdset.acceleratedDto.adsetId = adsetRow.campaign_id;
                                        campaignAdset.acceleratedDto.adsetName = adsetRow.campaign_name;
                                        campaignAdset.acceleratedDto.isActive = adsetUtils.getStatusByEnum(maybeCampaignDto !== undefined ? maybeCampaignDto.adsetStatus : "PAUSED");
                                        adsets.unshift(campaignAdset);
                                    } else {
                                        this.aggregateAdsetData(adsets[index].adsetData, adsetRow);
                                    }
                                }
                            } else {//show only adsets
                                if (adsetRow.budget_level !== CAMPAIGN_BUDGET_LEVEL) {
                                    adsets.push(adset);
                                }
                            }
                        } else if (adsetIds.includes(adsetRow.adsetId)) {
                            //sum up the values for the same adset
                            let index = adsets.findIndex(adset => adset.adsetData.adsetId === adsetRow.adsetId);
                            this.aggregateAdsetData(adsets[index].adsetData, adsetRow);
                        }
                        adsetIds.push(adsetRow.adsetId);
                    });
                    if (totals.rev > 0 && totals.spend > 0) {
                        totals.roi = totals.rev / (totals.spend * commission)
                    }
                    if (totals.testRev > 0 && totals.testSpend > 0) {
                        totals.testRoi = totals.testRev / (totals.testSpend * commission)
                    }
                    if (totals.rev > 0 && totals.spendLastHour > 0) {
                        totals.roiLastHour = totals.rev / (totals.spendLastHour * commission)
                    }
                    if (adsets.length > 0) {
                        this.setState({ initAdsets: true, init: true, errorMessage: null, adsets: adsets, lastUpdate: lastUpdate, lastUpdateFB: lastUpdateFB, adsetDataInProcess: false, totals: totals });
                    } else {
                        this.setState({ init: false, errorMessage: "Failed to get data for  adsets", adsetDataInProcess: false });

                    }
                } else {
                    this.setState({ init: false, errorMessage: "The response received contains 0 adsets, maybe try again later..", adsetDataInProcess: false });
                }
            } else {
                toastr.error("Failed to Fetch Adsets Data");
            }


        } else {
            toastr.error("An error occured. Could not locate selected account");
        }
    }

    handleAdsetActions(userChanges) {
        if (userChanges.length <= 0) {
            toastr.warn("No pending changes to save");
        } else {

            this.setState({ savingAdsets: formStatus.CONFIRMATION_REQUIRED });
            if (this.state.formConfirmed === null) {
                this.setState({ savingAdsets: formStatus.CONFIRMATION_REQUIRED, userChanges: userChanges });
            } else {
                this.saveAdsetActions(userChanges);
            }
        }
    }

    buildUrlSuffix(withCampaigns, GHOnly) {
        let baseForSuffix = GHOnly ? "StopLossByAccountGHOnly" : "StopLossByAccount";
        return baseForSuffix + "/?withCampaigns=" + withCampaigns + "&GHOnly=" + GHOnly;
    }


    saveAdsetActions(userChanges) {
        let userChangeInDollar = [];
        let currAcc;
        userChanges.forEach(acc => {
            currAcc = JSON.parse(JSON.stringify(acc));//creating a copy and not using the same reference 

            //add snowflake data to dto
            let index = this.state.adsets.findIndex(adset => adset.acceleratedDto.adsetId == acc.acceleratedAdsetId);
            if (index !== -1) {
                let adset = this.state.adsets[index];
                currAcc.snowflakeData = adset.adsetData;
            }

            let keys = Object.keys(acc);
            keys.forEach(key => {
                if (key == 'dailyBudget' || key == 'bidAmount' || key == 'budget') {
                    let value = acc[key] * 100;
                    if (key == 'dailyBudget') {
                        if (!adsetUtils.isDailyAdset(this.state.adsets[index].adsetData.adset_schedule_days)) {
                            delete currAcc.dailyBudget;
                        } else {
                            currAcc.dailyBudget = value;
                        }
                    } else if (key == 'budget') {
                        currAcc.lifetimeBudget = value;
                    } else {
                        currAcc[key] = value;
                    }
                }
            });

            userChangeInDollar.push(currAcc);
        });

        this.setState({ savingAdsets: formStatus.IN_PROGRESS });
        genericFetcherFactory(`/api/facebook-interface/Adset/updateAllOnFacebook?isCampaignType=` + this.state.isCampaignView, "UPDATE_ADSETS", "Failed to save all adsets", "POST",
            {
                method: "POST",
                body: JSON.stringify({
                    accountId: this.state.selectedAccount.accountId,
                    dtoList: userChangeInDollar
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })().then(result => {
                if (result.success === true) {
                    this.setState({ savingAdsets: formStatus.IDLE, userChanges: [] });
                    toastr.success("Adset have been updated");
                }
            });
    }

    async fetchAccountProducts(accountProducts) {
        return genericFetcherFactory("/api/product/Products/byProductIdList", "PRODUCT_LIST", "Failed to get productlist", "POST",
            {
                method: "POST",
                body: JSON.stringify(accountProducts),
                headers: { "Content-Type": "application/json" }
            })()
    }

    async fetchAdsetsByAccounts(account, withCampaigns = false, GHOnly = false) {
        //log params in one line with labels for easy reading
        //console.log("fetchAdsetsByAccounts: ", "account: ", account, "withCampaigns: ", withCampaigns, "GHOnly: ", GHOnly);

        let urlsuffix = this.buildUrlSuffix(withCampaigns, GHOnly);
        return genericFetcherFactory("/api/facebook-interface/Adsets/" + urlsuffix, "Daily", "Failed toget Adsets", "POST",
            {
                method: "POST",
                body: JSON.stringify({ ...account, accountType: "Facebook" }),
                headers: { "Content-Type": "application/json" }
            })()
    }

    renderAdsetDataView(isGHOnly) {
        return (
            <>
                <MediaQuery minWidth={900} >
                    <AdsetDataView
                        adsets={this.state.adsets}
                        type="stoploss"
                        submitFunction={this.handleAdsetActions}
                        isMobile={false}
                        hiddenColumns={isGHOnly ? StoplossGHOnlyColumnsToHide : null}
                        isCampaignView={this.state.isCampaignView}
                    />
                </MediaQuery>
                <MediaQuery maxWidth={899} >
                    <AdsetDataView
                        adsets={this.state.adsets}
                        type="stoploss"
                        submitFunction={this.handleAdsetActions}
                        isMobile={true}
                        hiddenColumns={isGHOnly ? StoplossGHOnlyColumnsToHide : null}
                        isCampaignView={this.state.isCampaignView}
                    />
                </MediaQuery>
            </>
        )
    }

    render() {
        return (
            <Container fluid className="p-0" id="StopLossTool">
                <Modal size="xl"
                    show={this.state.savingAdsets !== formStatus.IDLE}
                    centered
                >
                    <Modal.Header tag="h2" toggle={() => this.setState({ savingAdsets: formStatus.IDLE })}>
                        Update Adsets {this.state.savingAdsets}
                    </Modal.Header>
                    <Button color="secondary" size="small"
                        style={{ position: "absolute", top: "10px", right: "10px" }}
                        onClick={e => this.setState({ savingAdsets: formStatus.IDLE })}>
                        Close
                    </Button>
                    <Modal.Body>
                        {this.state.savingAdsets === formStatus.IN_PROGRESS &&
                            <Loader content={["Stopping adests", "Please come again"]} />
                        }
                        {this.state.savingAdsets === formStatus.CONFIRMATION_REQUIRED &&
                            <>
                                <ConfirmationTableView
                                    userActions={this.state.userChanges}
                                    adsets={this.state.adsets}
                                    className='SL_ConfirmationTableView'
                                />
                                <Button onClick={() => this.saveAdsetActions(this.state.userChanges)} color="primary">Confirm Changes</Button>
                            </>
                        }
                    </Modal.Body>
                </Modal>

                <Card>
                    <Card.Header className="pb-1">
                        <MobileOnlyAlert message={"Please rotate your mobile phone for better view"} />
                        <Card.Title tag="h1">Stop Loss Adset management </Card.Title>

                        <InfoBar infoText={"Modify running ad sets by adjusting bids, budgets, and delivery status."} />


                        <div className="stoploss-radio-controls">
                            <FormControl className="client-radio">
                                <FormLabel>Client</FormLabel>
                                <RadioGroup
                                    defaultValue="GHOnly"
                                    row
                                    name="client-radio-buttons-group"
                                    onChange={this.handleClientChange}
                                >
                                    <FormControlLabel value="GHOnly" control={<adsetUtils.BpRadio />} label="GH Only (New)" />
                                    <FormControlLabel value="NonGH" control={<adsetUtils.BpRadio />} label="Non GH (Old)" />
                                </RadioGroup>
                            </FormControl>
                            <FormControl className="data-type-radio">
                                <FormLabel>Data Type</FormLabel>
                                <RadioGroup
                                    defaultValue="adset"
                                    row
                                    name="data-type-radio-buttons-group"
                                    onChange={this.handleDataTypeChange}
                                >
                                    <FormControlLabel value="adset" control={<adsetUtils.BpRadio />} label="Adset" />
                                    <FormControlLabel value="campaign" control={<adsetUtils.BpRadio />} label="Campaign" />
                                </RadioGroup>
                            </FormControl>
                        </div>

                    </Card.Header>
                    <Card.Body className="pt-0">

                        <div className="pb-2">
                            {this.state.selectedAccount !== null && !this.state.isGHOnly &&
                                <>
                                    <Badge bg="warning" className="me-2">BE Update: {this.state.lastUpdate}</Badge>
                                    <Badge bg="warning" className="me-2">FB Update: {this.state.lastUpdateFB}</Badge>
                                </>
                            }
                            {this.state.selectedAccount !== null && this.state.isGHOnly &&
                                <>
                                    <Badge bg="warning" className="me-2">Update: {this.state.lastUpdate}</Badge>
                                </>
                            }

                            {this.state.totals.spend > 0 &&
                                <Badge bg="success" className="me-2">Overall Spend: {currencyFormatter.format(Number(this.state.totals.spend))}</Badge>
                            }
                            {this.state.totals.conversions > 0 &&
                                <Badge bg="secondary" className="me-2">Total Conversions: {(Number(this.state.totals.conversions))}</Badge>
                            }
                            {this.state.totals.sales > 0 &&
                                <Badge bg="secondary" className="me-2">Total Sales: {(Number(this.state.totals.sales))}</Badge>
                            }
                            {this.state.totals.roi > 0 &&
                                <Badge bg="info" className="me-2">ROI: {percentFormatter.format(Number(this.state.totals.roi))}</Badge>
                            }
                            {this.state.totals.roiLastHour > 0 &&
                                <Badge bg="info" className="me-2">LAST Hour ROI: {percentFormatter.format(Number(this.state.totals.roiLastHour))}</Badge>
                            }
                            {this.state.totals.spendRemainingForToday > 0 &&
                                <Badge bg="info" className="me-2">Max. Spending remaining for today: {currencyFormatter.format(Number(this.state.totals.spendRemainingForToday))}</Badge>
                            }
                            {this.state.totals.totalTestSpentToday > 0 &&
                                <Badge bg="info" className="me-2">Test Spend: {currencyFormatter.format((Number(this.state.totals.totalTestSpentToday)))}</Badge>
                            }
                            {this.state.totals.testRoi > 0 &&
                                <Badge bg="info" className="me-2">Test ROAS: {percentFormatter.format(Number(this.state.totals.testRoi))}</Badge>
                            }

                        </div>
                        {!this.props.facebookUserData.isLoggedIn ?
                            <Button color="info"><FacebookContextComponent shouldRender={true} /></Button>
                            :
                            <>

                                {(this.state.initAdsets === null || this.state.selectedAccount !== null) &&
                                    <>
                                        <div className="mb-4" style={{ display: "flex", justifyContent: "space-between", zIndex: 1, position: 'relative' }}>
                                            <AccountDropdown
                                                widthClass={"col-12 col-sm-4"}
                                                updateStateCallback={(params) => { console.log(params) }}
                                                accountChanged={this.accountChanged}
                                                selectedAccount={this.state.selectedAccount}
                                                platform='FACEBOOK'
                                                clientType={this.state.isGHOnly ? clientType.GH : clientType.notGH}
                                                itemActive={AccountIsActive.ACTIVE} />
                                        </div>
                                    </>
                                }

                                {this.state.adsets !== null ? this.renderAdsetDataView(this.state.isGHOnly) : null}
                                {this.state.init === false &&
                                    <GenericErrorAlert errorMessage={this.state.errorMessage} />
                                }
                                {this.state.adsetDataInProcess &&
                                    <Loader content={["Adsets are coming", "Prepare to stop adsets"]} />
                                }

                            </>
                        }
                    </Card.Body>
                </Card>
            </Container >
        )
    }
}

export default connect((store) => {
    return {
        facebookUserData: store.userContext.facebookUserData
    }
})(StoplossTool)
