import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import * as Icon from "react-feather";
import { toastr } from "react-redux-toastr";
import { Button, Modal, Container, Card } from "react-bootstrap";
import GenericErrorAlert from "../../../components/GenericErrorAlert";
import Loader from '../../../components/Loader';
import { CACHE_EXPIRATION_DEFAULTS } from "../../../services/qsCache";
import { handleErrorResponse } from "../../../utils/ajaxErrorHandler";
import { addHours } from "../../../utils/dateUtils";
import EntitySlackChannelsView from "../../slack/EntitySlackChannelsView";
import SlackAlertChannelEditForm from "../../slack/SlackAlertChannelEditForm";
import SlackChannelService, { slackModalStyles } from "../../slack/slackService";
import ProductEditForm, { FormModes } from './ProductEditForm';
import { genericCachedFetcherFactory, genericFetcherFactory } from "../../../utils/requestUtils";
import AllowedAdDomainsEditForm from "../allowedDomains/AllowedAdDomainsEditForm";



export default class Products extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            modal: false,
            slackModal: false,
            domainsModal: false,
            modal: false,
            productData: {
                productId: null
            },
            initSuccess: null
        };
        this.slackService = new SlackChannelService();
        this.updateSlackChannelsSuccessCallback = this.updateSlackChannelsSuccessCallback.bind(this);
        this.updateProduct = this.updateProduct.bind(this);
        this.fetchBrands = this.fetchBrands.bind(this);
        this.fetchIndustries = this.fetchIndustries.bind(this);
        this.fetchProducts = this.fetchProducts.bind(this);
        this.updateStateCallback = this.updateStateCallback.bind(this);
    }

    updateStateCallback(key, value) {
        this.setState({ [key]: value });
    }

    updateProduct(productObj, productId) {
        this.setState({ ...this.state, formSaving: true })

        genericFetcherFactory("/api/product/Products/Product/" + productId, "PRODUCTS", "Failed to update Products", "PATCH",
            {
                method: "PATCH",
                body: JSON.stringify(productObj),
                headers: { 'Content-Type': 'application/json' }
            })().then((res) => {
                if (res.success) {
                    let updatedProduct = res.data;
                    let newProducts = [...this.state.products];
                    let productIndex = newProducts.findIndex(item => (item.productId === productId));
                    let productBrand = this.state.brands.find((item) => { return item.brandId == updatedProduct.brandId })
                    updatedProduct.brandName = productBrand !== undefined ? productBrand.brandName : "[Not found]";
                    updatedProduct.industryName = updatedProduct.industry != null ? updatedProduct.industry.industryName : "None";
                    updatedProduct.industryId = updatedProduct.industry != null ? updatedProduct.industry.industryId : 0;
                    newProducts.splice(productIndex, 1, updatedProduct);
                    this.setState({ ...this.state, products: newProducts, formSaving: false });
                    toastr.success("Update Success", updatedProduct.productName + " was updated successfully.")
                } else {
                    this.setState({ ...this.state, formSaving: false });
                    handleErrorResponse(res, "Failed to Update Products");
                }
            }).catch(error => {
                console.log(error);
            });

    }


    async fetchProducts() {
        return genericCachedFetcherFactory(`/api/product/Products`, "PRODUCTS", "Failed to fetch PRODUCTS data", addHours(1))().then(result => {
            if (result.success === true) {
                return result.data;
            } else {
                return undefined;
            }
        });
    }

    async fetchBrands() {
        return genericCachedFetcherFactory("/api/crm/Brands", "BRANDS", "Failed to fetch from Brands's API Service", addHours(CACHE_EXPIRATION_DEFAULTS.Accounts))().then(res => {
            if (res.success == true) {
                return res.data;

            } else {
                return [];
            }
        })
    }

    async fetchIndustries() {
        return genericCachedFetcherFactory("/api/product/Industries", "INDUSTRIES", "Failed to fetch from Industries's API Service", addHours(CACHE_EXPIRATION_DEFAULTS.Accounts))().then(res => {
            if (res.success == true) {
                return res.data;

            } else {
                return [];
            }
        })
    }


    updateProductData() {
        genericFetcherFactory("/api/product/Products", "PRODUCTS", "Failed to fetch from Products API Service")().then(res => {
            if (res.success == true) {
                let result = res.data;
                result = result.map(((product) => {
                    let productBrand = this.state.brands.find((item) => { return item.brandId === product.brandId });
                    product.brandName = productBrand !== undefined ? productBrand.brandName : "[Not found]";
                    product.industryName = product.industry != null ? product.industry.industryName : "None";
                    product.industryId = product.industry != null ? product.industry.industryId : 0;
                    product.slackChannelsNameList = <EntitySlackChannelsView entityType="product" entityId={product.productId} />;
                    return product;
                }));
                this.setState({ ...this.state, products: result, initSuccess: true });
            } else {
                this.setState({ ...this.state, initSuccess: false });
                handleErrorResponse(res, "Failed to fetch Products");
            }
        })
    }

    handleProductSlacks(product) {
        product.slackChannelsNameList = <EntitySlackChannelsView entityType="product" entityId={product.productId} />;
        return product;
    }

    processData(brands, products) {
        return products.map(((product) => {
            //Set products crm data
            let productBrand = brands.find((item) => { return item.brandId === product.brandId });
            product.brandName = productBrand !== undefined ? productBrand.brandName : "[Not found]";
            product.industryName = product.industry != null ? product.industry.industryName : "None";
            product.industryId = product.industry != null ? product.industry.industryId : 0;
            //process product Slack Channel data - handle case of no relevenant dto
            product = this.handleProductSlacks(product)
            return product;
        }));


    }

    async componentDidMount() {
        let brands = [];
        let industries = [];
        let products = [];
        [brands, industries, products] = await Promise.all([
            this.fetchBrands(),
            this.fetchIndustries(),
            this.fetchProducts(),
        ]);
        if (products !== undefined) {
            let state = { ...this.state };
            state.industries = [{ industryId: -1, industryName: "Select Industry" }, { industryId: 0, industryName: "No Industry" }, ...industries];
            state.brands = [{ brandId: 0, brandName: "Select Brand" }, ...brands];
            state.products = this.processData(brands, products);
            await this.slackService.build("product");
            state.initSuccess = true;
            this.setState(state);
        }
    }

    updateSlackChannelsSuccessCallback(data) {
        let newProducts = [...this.state.products];
        let updatedProduct = { ...newProducts.find(product => product.productId === data.entityId) };
        updatedProduct = this.handleProductSlacks(updatedProduct);
        let index = newProducts.findIndex(product => product.productId === updatedProduct.productId);
        if (index != -1) {
            newProducts.splice(index, 1, updatedProduct);
            this.setState({ modal: false, initSuccess: true, products: newProducts, formSaving: false });
            toastr.success("Slack Channel added to product successfully.");
        } else {
            console.log("index not found");

            this.setState({ formSaving: false, modal: false });
        }


    }


    // Boostrap table configuration 
    // Expand row configuration for editing rows in the Products table
    expandRow = {

        renderer: row => {
            this.setState({ productData: row });

            return (
                <>
                    <div className="ml-5 mr-5 mt-3 mb-3">
                        <ProductEditForm mode={FormModes.EDIT} productData={row} submitCallback={this.updateProduct} history={this.props.history} />
                        <Button id="slackAlertChannels" variant="secondary" onClick={e => this.setState({ slackModal: true })}>
                            Add Slack Alert Channels
                        </Button>
                        <Button id="AllowdDomains" variant="success" className="ms-2" onClick={e => this.setState({ domainsModal: true })}>
                            Add Allowed Domain
                        </Button>
                    </div>
                    <div>
                        <Modal show={this.state.slackModal === true} size="lg" style={slackModalStyles} centered>
                            <Button className='col-12' variant="secondary" onClick={e => this.setState({ slackModal: false })}>
                                Close
                            </Button>
                            <Modal.Header>Slack Alert Channels</Modal.Header>
                            <Modal.Body className="text-center m-2">
                                <Container fluid className="mb-0">
                                    <SlackAlertChannelEditForm
                                        entityId={row.productId}
                                        entityType={"product"}
                                        updateSuccessCallback={this.updateSlackChannelsSuccessCallback}
                                        failedCallback={(err) => { toastr.error(err) }}
                                    />
                                </Container>
                            </Modal.Body>
                        </Modal>
                    </div>
                    <div>
                        <Modal show={this.state.domainsModal === true} size="lg" style={slackModalStyles} centered>
                            <Button className='col-12' variant="secondary" onClick={e => this.setState({ domainsModal: false })}>
                                Close
                            </Button>
                            <Modal.Header>Add Allowed Domain</Modal.Header>
                            <Modal.Body className="text-center m-2">
                                <Container fluid className="mb-0">
                                    <AllowedAdDomainsEditForm
                                        selectedProduct={this.state.productData}
                                        updateStateCallback={this.updateStateCallback}
                                        usedAsModal='true'
                                        customWidth = 'col-12'
                                    />
                                </Container>
                            </Modal.Body>
                        </Modal>
                    </div>
                </>

            )
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return (
                    <Icon.Edit className="feather align-middle" />
                );
            }
            return (
                <Icon.Edit2 className="feather align-middle" />
            );
        },
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            return "";
        },
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandColumnPosition: 'right'
    };

    // Column configuration for the products table
    productsColumns = [
        {
            dataField: "productId",
            text: "ID",
            headerStyle: { width: "30px" },
        },
        {
            dataField: "productName",
            text: "Product Name",
            sort: true
        },
        {
            dataField: "productSummary",
            text: "Product Summary",
        },
        {
            dataField: "brandName",
            text: "Brand",
            sort: true
        },
        {
            dataField: "industryName",
            text: "Industry",
            sort: true
        },
        {
            dataField: "slackChannelsNameList",
            text: "Slack Alert Channel list",
            sort: true
        }
    ]

    render() {

        if (this.state.initSuccess === true) {
            return (
                <Container fluid className="p-0">
                    <Modal isOpen={this.state.formSaving === true} centered>
                        <Modal.Header>
                            Saving Product...
                        </Modal.Header>
                        <Modal.Body className="text-center m-3">
                            <Loader width="wide" />
                        </Modal.Body>
                    </Modal>
                    <Card>
                        <Card.Header>
                            <Card.Title tag="h5" className="mb-0">
                                Products
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <BootstrapTable
                                bootstrap4
                                keyField="productId"
                                bordered={false}
                                striped
                                hover
                                data={this.state.products ? this.state.products : []}
                                columns={this.productsColumns}
                                expandRow={this.expandRow}
                            />
                        </Card.Body>
                    </Card>

                </Container>
            )
        } else if (this.state.initSuccess === false) {
            return (
                <GenericErrorAlert />
            );
        } else {
            return (
                <Card>
                    <Card.Header>
                        <Card.Title tag="h5" className="mb-0">
                            Products
                        </Card.Title>
                    </Card.Header>
                    <Loader width="wide" />
                </Card>
            );
        }
    }
}