import { DataGridPremium, getGridStringOperators, GRID_AGGREGATION_FUNCTIONS, GRID_AGGREGATION_ROOT_FOOTER_ROW_ID, GridActionsCellItem, GridFilterInputValue } from '@mui/x-data-grid-premium';
import { LicenseInfo } from '@mui/x-license';

import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import * as Icon from "react-feather";
import MediaQuery from 'react-responsive';

import { CheckSquare, Square } from "react-feather";
import * as adsetUtils from '../../../utils/adsetUtils';

import AdSetExpansionPanelContent from './AdSetExpansionPanelContent';
LicenseInfo.setLicenseKey(
    'eeb39e1109486bc6908b51c043f745a5Tz04ODYwMixFPTE3NDQ5NTI5ODIwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
);

export const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export const percentFormatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});
export default class AdSetExpansionDataView extends React.PureComponent {
    constructor(props) {
        super(props)
        this.revertAdsetChanges = this.revertAdsetChanges.bind(this);
        this.duplicateAll = this.duplicateAll.bind(this);
        this.setColumnListWithSFData = this.setColumnListWithSFData.bind(this);
        this.toggleFilterState = this.toggleFilterState.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);

        this.state = {
            adsets: [],
            initState: [],
            userActionAdsets: [],
            columns: [],
            filterStatus: false
        }
    }

    setColumnListWithSFData(exampleData, formCols) {
        Object.keys(exampleData).forEach(example => {
            let col = {
                dataField: example,
                text: example,
                sort: false, editable: false,
                headerStyle: { width: "75px" },
            }
            formCols.push(col);

        });
        return formCols;
    }

    componentDidMount() {
        if (this.props.adsets !== null || this.props.adsets.length > 0) {
            let formCols = this.setGridColsFull(this.revertAdsetChanges, this.props.isMobile ? 90 : 450, this.toggleFilterState);
            this.setState({ adsets: this.props.adsets, initState: [...JSON.parse(JSON.stringify(this.props.adsets))], columns: formCols })
        }
    }

    //Edit postId and adName
    handleFieldChange(e, params) {
        const { name, value } = e.target;
        let newValue = value;

        if (name === "page_post_id") {
            newValue = value.replace(/\D/g, ''); // Remove any non-digit characters for post_id
        }

        const isChanged = params.allTouched;
        const updatedAdsets = this.state.adsets.map(row => {
            if (row.adset_id === params.row.adset_id) {
                return {
                    ...row,
                    [name]: newValue,
                    isChanged: isChanged,
                    isValid: params.row.isValid
                };
            }
            return row;
        });

        let updatedUserActionAdsets = [...this.state.userActionAdsets];
        const actionIndex = updatedUserActionAdsets.findIndex((action) => action.adset_id === params.row.adset_id);

        if (actionIndex !== -1) {
            // Update the existing action with new value
            updatedUserActionAdsets[actionIndex] = {
                ...updatedUserActionAdsets[actionIndex],
                [name]: newValue,
                adset_name: params.row.adset_name,
                ad_id: params.row.ad_id

            };
        } else {
            // Create a new action record for this row with the updated field
            updatedUserActionAdsets.push({
                adset_id: params.row.adset_id,
                [name]: newValue,
                adset_name: params.row.adset_name,
                ad_id: params.row.ad_id
            });
        }

        this.setState({
            adsets: updatedAdsets,
            userActionAdsets: updatedUserActionAdsets
        });
    }


    revertAdsetChanges(adset) {
        let newAdsets = [...this.state.adsets];
        let userActionAdsets = [...this.state.userActionAdsets];
        let rowIndex = newAdsets.findIndex(test => test.adset_id == adset.adset_id);
        let userActionAdsetsIndex = userActionAdsets.findIndex(ua => ua.adset_id == adset.adset_id);
        if (rowIndex != -1) {
            let updatedRow = { ...this.state.initState[rowIndex] };
            updatedRow.isChanged = false;
            newAdsets.splice(rowIndex, 1, updatedRow);
            if (userActionAdsetsIndex !== -1) {
                userActionAdsets.splice(userActionAdsetsIndex, 1)
            }
            this.setState({ adsets: newAdsets, userActionAdsets: userActionAdsets });
        }
    }

    toggleFilterState() {
        this.setState(prevState => ({
            filterStatus: !prevState.filterStatus
        }))
    }


    duplicateAll() {
        this.props.submitFunction(this.state.userActionAdsets);
    }

    setGridColsFull(revertAdsetChanges, adsetNameWidth, toggleFilterState) {
        const columns = [
            {
                field: "campaign_name",
                renderHeader: (params) => (
                    <>
                        {'Campaign Name'}
                        <GridActionsCellItem
                            label="Filter"
                            icon={<Icon.Filter size={18} />}
                            onClick={() => toggleFilterState()}
                            title="Filter"
                        />
                    </>
                ),
                headerClassName: 'campaignNameCol shortView',
                cellClassName: 'campaignNameCol shortView',
                filterOperators: [
                    ...getGridStringOperators(),
                    {
                        value: "noContain",
                        getApplyFilterFn: (
                            filterItem
                        ) => {
                            if (
                                !filterItem.field ||
                                !filterItem.value ||
                                !filterItem.operator
                            ) {
                                return null;
                            }

                            const filterRegex = new RegExp(filterItem.value, "i");
                            return (value) => {
                                const rowValue = value;
                                return !filterRegex.test(rowValue?.toString() || "");
                            };
                        },
                        InputComponent: GridFilterInputValue
                    }
                ],
                width: adsetNameWidth,
                resizable: true,
                renderCell: (params) => (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {params.value}
                            </Tooltip>
                        }
                    >
                        <div>{params.value}</div>
                    </OverlayTrigger>
                ),
                aggregable: false,
            },
            {
                field: "adset_name",
                renderHeader: (params) => (
                    <>
                        {'Adset Name'}
                        <GridActionsCellItem
                            label="Filter"
                            icon={<Icon.Filter size={18} />}
                            onClick={() => toggleFilterState()}
                            title="Filter"
                        />
                    </>
                ),
                headerClassName: 'adsetNameCol shortView',
                cellClassName: 'adsetNameCol shortView',
                filterOperators: [
                    ...getGridStringOperators(),
                    {
                        value: "noContain",
                        getApplyFilterFn: (
                            filterItem
                        ) => {
                            if (
                                !filterItem.field ||
                                !filterItem.value ||
                                !filterItem.operator
                            ) {
                                return null;
                            }

                            const filterRegex = new RegExp(filterItem.value, "i");
                            return (value) => {
                                const rowValue = value;
                                return !filterRegex.test(rowValue?.toString() || "");
                            };
                        },
                        InputComponent: GridFilterInputValue
                    }
                ],
                width: adsetNameWidth,
                resizable: true,
                renderCell: (params) => (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {params.value}
                            </Tooltip>
                        }
                    >
                        <div>{params.value}</div>
                    </OverlayTrigger>
                ),
                aggregable: false,
            },
            {
                field: "spend",
                headerName: "Spend",
                sortable: true,
                headerClassName: 'sfDataHeader',
                cellClassName: 'sfDataCell shortView',
                type: 'number',
                headerAlign: 'center',
                align: 'center',
                availableAggregationFunctions: ['max', 'min', 'sum', 'avg', 'size'],
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? currencyFormatter.format(Number(value)) : "N/A") },
                renderHeaderFilter: () => null

            },
            {
                field: "bid", headerName: "Bid", editable: false,
                headerClassName: 'bidCol shortView',
                cellClassName: 'bidCol shortView',
                type: 'number',
                headerAlign: 'center',
                align: 'center',
                width: 80,
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? currencyFormatter.format(Number(value)) : "N/A") },
                renderHeaderFilter: () => null,
                aggregable: false,
            },

            {
                field: "roi",
                headerName: "ROI",
                sort: false,
                editable: false,
                headerClassName: 'sfDataHeader fullView',
                cellClassName: 'sfDataCell fullView',
                headerAlign: 'center',
                align: 'center',
                type: 'number',
                availableAggregationFunctions: ['roiAggregation'],
                resizable: false,
                valueFormatter: (value) => { return ((value !== null && value !== undefined) ? percentFormatter.format(Number(value)) : "N/A") },
                renderHeaderFilter: () => null
            },
            {
                field: "cpa_be",
                headerName: "CPA BE",
                sort: false,
                editable: false,
                headerClassName: 'sfDataHeader fullView',
                cellClassName: 'sfDataCell fullView',
                type: 'number',
                headerAlign: 'center',
                align: 'center',
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? currencyFormatter.format(Number(value)) : "N/A") },
                renderHeaderFilter: () => null
            },
            {
                field: "cpa_ui",
                headerName: "CPA UI",
                sort: false,
                editable: false,
                headerClassName: 'sfDataHeader fullView',
                cellClassName: 'sfDataCell fullView',
                headerAlign: 'center',
                align: 'center',
                type: 'number',
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? currencyFormatter.format(Number(value)) : "N/A") },
                renderHeaderFilter: () => null
            },
            {
                field: "revert",
                type: "actions",
                aggregable: false,
                headerClassName: 'revertButtonCol shortView',
                cellClassName: 'revertButtonCol shortView',
                width: 35,
                resizable: false,
                getActions: (params) =>
                    params.id === GRID_AGGREGATION_ROOT_FOOTER_ROW_ID ? [] : [<GridActionsCellItem
                        label="Revert"
                        icon={<Icon.RotateCcw />}
                        onClick={() => revertAdsetChanges(params.row)}
                        title="Revert"
                    />],
                renderHeaderFilter: () => null
            }
        ]
        return columns;
    }

    render() {
        const changedRows = this.state.adsets.filter(row => row.isChanged);
        // Enable the 'Duplicate All' button if the two columns are touched and valid.
        const canDuplicateAll = changedRows.length > 0 && changedRows.every(row => row.isValid);
        const rows = [...this.state.adsets];
        const hideColumnsOnMobile = this.props.hideColumnsOnMobile !== undefined ? this.props.hideColumnsOnMobile : '';

        if (this.state.columns !== undefined && this.state.columns.length > 0) {
            return (
                <>
                    <div className='tableWrap' id='tableWrap'>
                        <MediaQuery maxWidth={1024}>
                            <Button className="btnScroll" size="sm"
                                onClick={() => { document.getElementById("tableWrap").scrollIntoView(); }}>
                                <Icon.ArrowUp />
                            </Button>
                        </MediaQuery>

                        <DataGridPremium
                            slots={{
                                detailPanelExpandIcon: Square,
                                detailPanelCollapseIcon: CheckSquare,
                            }}
                            sx={{ width: '100%', height: 'calc(100vh - 50px)', maxHeight: '700px' }}
                            rows={rows}
                            rowHeight={this.props.isMobile ? 56 : 90}
                            columns={this.state.columns}
                            aggregationFunctions={{
                                ...GRID_AGGREGATION_FUNCTIONS,

                            }}
                            disableColumnReorder={false}
                            initialState={{
                                aggregation: {
                                    model: {
                                        // Aggregation functions can be set here...
                                    },
                                },
                                density: "compact",
                                pinnedColumns: { right: ['revert'] },

                                columns: {
                                    columnVisibilityModel: {
                                        ...this.props.hiddenColumns,
                                        ...hideColumnsOnMobile,
                                    },
                                },
                                sorting: {
                                    sortModel: [{ field: 'spend', sort: 'desc' }],
                                }
                            }}
                            getRowId={(row) => row.adset_id}
                            headerFilters={this.state.filterStatus}
                            getDetailPanelContent={(params) => {
                                const currentRow = this.state.adsets.find((row) => row.adset_id === params.row.adset_id);
                                return (
                                    <AdSetExpansionPanelContent
                                        params={params}
                                        currentRow={currentRow}
                                        handleFieldChange={this.handleFieldChange}
                                    />
                                );
                            }}
                            getDetailPanelHeight={() => 100}

                        />
                        <Button disabled={!canDuplicateAll} className="submit-btn col-12 col-lg-3 mt-3" variant="primary"
                            onClick={this.duplicateAll}>Duplicate All
                        </Button>
                    </div>
                </>
            );
        } else {
            return <Icon.Loader />;
        }
    }

}
