import React from "react";
import {
    Button,
    Card,
    Container,
    Modal
} from "react-bootstrap";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import AccountDropdown from "../../../components/AccountDropdown";
import GenericErrorAlert from "../../../components/GenericErrorAlert";
import Loader from '../../../components/Loader';
import { genericFetcherFactory } from "../../../utils/requestUtils";
import { AccountIsActive } from "../../prods-and-accounts/account/accountUtils";

import MediaQuery from "react-responsive";
import FacebookContextComponent from "../../../components/FacebookContextComponent";
import InfoBar from "../../../components/InfoBar";
import MobileOnlyAlert from "../../../components/MobileOnlyAlert";
import { formStatus } from "../../../utils/formUtils";
import AdSetExpansionDataView from "./AdSetExpansionDataView";
import ConfirmationTableViewAdSetExpansion from "./ConfirmationTableViewAdSetExpansion";

class AdSetExpansionTool extends React.PureComponent {
    constructor(props) {
        super(props);

        this.accountChanged = this.accountChanged.bind(this);
        this.fetchAdsetsByAccountsFromLambda = this.fetchAdsetsByAccountsFromLambda.bind(this);
        this.parseLambdaData = this.parseLambdaData.bind(this);
        this.saveAdsetActions = this.saveAdsetActions.bind(this);
        this.handleAdsetActions = this.handleAdsetActions.bind(this);

        this.state = {
            loadingAdSets: false,
            selectedAccount: null,
            activeAccounts: [],
            adsetIds: [],
            showingSavePrompt: false,
            adsets: null,
            errorMessage: null,
            savingAdsetsFormStatus: formStatus.IDLE,
            userChanges: null,
            formConfirmed: null

        }
    }

    componentDidMount() {
    }

    accountChanged(account) {
        if (account !== undefined) {
            this.setState({ selectedAccount: account, adsetIds: [], adsets: null, duplicateAdsets: [], loadingAdSets: true, errorMessage: null });
            this.fetchAdsetsByAccountsFromLambda(account.accountPlatformId);
        } else {
            toastr.error("An error occured. Could not locate selected account");
        }
    }

    async fetchAdsetsByAccountsFromLambda(accountPlatformId) {
        return genericFetcherFactory(`/api/facebook-interface/ExpansionTool/getAdSetsToDuplicate/${accountPlatformId}`,
            "ExpansionTool", "Failed to get AdSets", "POST", {
            method: "POST",
            body: JSON.stringify({ ...accountPlatformId }),
            headers: { "Content-Type": "application/json" }
        })().then((res) => {
            if (res.success) {
                if (res.data.length > 0) {
                    this.parseLambdaData(res.data)
                } else {
                    this.setState({ errorMessage: "No adsets to duplicate, please try again later.", loadingAdSets: false });
                }
            } else {
                this.setState({ errorMessage: "Failed to get adSets to duplicate", loadingAdSets: false });
            }
        }).catch(error => {
            toastr.error(error)
        });
    }

    parseLambdaData(adsetsFromLambda) {
        adsetsFromLambda.forEach((responseAdsetRow) => {
            const pageId = responseAdsetRow.page_post_id.includes('_') ? responseAdsetRow.page_post_id.split('_')[1] : responseAdsetRow;
            responseAdsetRow.page_post_id = pageId;
        });

        this.setState({ adsets: adsetsFromLambda, adsetIds: [], loadingAdSets: false });
    }

    handleAdsetActions(userChanges) {
        if (userChanges.length <= 0) {
            toastr.warn("No pending changes to save");
        } else {
            this.setState({ savingAdsetsFormStatus: formStatus.CONFIRMATION_REQUIRED });
            if (this.state.formConfirmed === null) {
                this.setState({ savingAdsetsFormStatus: formStatus.CONFIRMATION_REQUIRED, userChanges: userChanges });
            } else {
                this.saveAdsetActions(userChanges);
            }
        }
    }

    convertObjToFacebookAdDTO(adsets) {
        return adsets.map(adset => ({
            adSetId: adset.adset_id,
            adName: adset.ad_name,
            pageId: adset.page_post_id,
            adSetName: adset.adset_name,
            adId: adset.ad_id
        }))
    }

    saveAdsetActions(adsets) {
        this.setState({ savingAdsetsFormStatus: formStatus.IN_PROGRESS });
        let dtoList = this.convertObjToFacebookAdDTO(adsets);

        genericFetcherFactory(`/api/facebook-interface/DuplicateAdSets/${this.state.selectedAccount.accountPlatformId}`, "DUPLICATE_ADSES", "Failed to duplicate", "POST", {
            method: "POST",
            body: JSON.stringify(dtoList),
            headers: { "Content-Type": "application/json" }
        })().then((res) => {
            if (res.success) {
                this.setState({
                    savingAdsetsFormStatus: formStatus.IDLE,
                    userChanges: [],
                    adsetIds: []
                });
                toastr.info("Duplicated successfully!")
            }
        }).catch(error => {
            toastr.error(error)
        });
    }


    render() {

        return (
            <Container fluid className="p-0">
                <Modal size="xl"
                    show={this.state.savingAdsetsFormStatus !== formStatus.IDLE}
                    centered
                >
                    <Modal.Header tag="h2" toggle={() => this.setState({ savingAdsetsFormStatus: formStatus.IDLE })}>
                        Duplicating Adsets {this.state.savingAdsetsFormStatus}
                    </Modal.Header>
                    <Button color="secondary" size="small"
                        style={{ position: "absolute", top: "10px", right: "10px" }} 
                        onClick={e => this.setState({ savingAdsetsFormStatus: formStatus.IDLE })}>
                        Close
                    </Button>
                    <Modal.Body>
                        {this.state.savingAdsetsFormStatus === formStatus.IN_PROGRESS &&
                            <Loader content={["Duplicating adsets", "Please wait"]} />
                        }
                        {this.state.savingAdsetsFormStatus === formStatus.CONFIRMATION_REQUIRED &&
                            <>
                                <ConfirmationTableViewAdSetExpansion
                                    userActions={this.state.userChanges}
                                    adsets={this.state.adsets}
                                />
                                <Button onClick={() => this.saveAdsetActions(this.state.userChanges)} color="primary" className="mt-3">Confirm Changes</Button>
                            </>
                        }
                    </Modal.Body>
                </Modal>

                <Card>
                    <Card.Header className="pb-1">
                        <MobileOnlyAlert message={"Please rotate your mobile phone for better view"} />
                        <Card.Title tag="h5">Expansion Tool</Card.Title>
                        <InfoBar infoText={"Duplicate ad sets on Facebook "} />
                    </Card.Header>
                    <Card.Body>

                        {!this.props.facebookUserData.isLoggedIn ?
                            <Button color="info"><FacebookContextComponent shouldRender={true} /></Button>
                            :
                            <>
                                <div className="mb-4" style={{ display: "flex", justifyContent: "space-between", zIndex: 1, position: 'relative' }}>
                                    <AccountDropdown
                                        widthClass={"col-12 col-sm-4"}
                                        updateStateCallback={(params) => { console.log(params) }}
                                        accountChanged={this.accountChanged}
                                        selectedAccount={this.state.selectedAccount}
                                        platform='FACEBOOK'
                                        itemActive={AccountIsActive.ACTIVE} />
                                </div>

                                {this.state.loadingAdSets === false && this.state.selectedAccount !== null &&

                                    this.state.adsets !== null && this.state.adsets.length > 0 &&
                                    <>
                                        <MediaQuery minWidth={900} >
                                            <AdSetExpansionDataView 
                                                adsets={this.state.adsets}
                                                submitFunction={this.handleAdsetActions}

                                            />
                                        </MediaQuery>
                                        <MediaQuery maxWidth={899} >
                                            <AdSetExpansionDataView
                                                adsets={this.state.adsets}
                                                submitFunction={this.handleAdsetActions}
                                            />
                                        </MediaQuery>
                                    </>
                                }
                                {this.state.loadingAdSets === true &&
                                    <Loader content={["Loading Ad Sets..."]} />
                                }
                                {this.state.errorMessage &&
                                    <GenericErrorAlert errorMessage={this.state.errorMessage} />
                                }
                            </>
                        }

                    </Card.Body>
                </Card>
            </Container>
        )
    }
}


export default connect((store) => {
    return {
        facebookUserData: store.userContext.facebookUserData
    }
})(AdSetExpansionTool)
