import React from "react";
import {
    Info as InfoIcon
} from "react-feather";
import {Alert} from "react-bootstrap";
import CloseButton from 'react-bootstrap/CloseButton';

export default class InfoBar extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showInfo: true,
        }

        this.infoText = this.props.infoText
    }

    render() {
        return (
            <>
                {this.state.showInfo && <Alert className="alert-outline mb-1 mt-1">
                    <div className="alert-icon p-2">
                        <InfoIcon />
                    </div>
                    <div className="alert-message pt-1 pb-1 d-flex justify-content-between">{this.infoText}
                        <CloseButton
                            className="m-1"
                            onClick={() => this.setState({ showInfo: false })}
                            aria-label="Close alert"
                        />
                    </div>
                </Alert>}
            </>
        )
    }
}

